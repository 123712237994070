import { Card, CardBody, Image } from '@chakra-ui/react';

function CardCaraousel(props) {
  return (
    <Card maxW="sm" shadow="lg">
      <CardBody>
        <Image
          boxSize={['150px', null, null, '350px']}
          src={props.url}
          alt={props.alt}
          objectFit="cover"
          borderRadius="lg"
        />
      </CardBody>
    </Card>
  );
}

export default CardCaraousel;
