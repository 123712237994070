import { useState } from 'react';

import {
  useFetchWithSwr,
  errorText,
  spinner,
} from '../../../lib/fetching-util';
import { readableDate, numMonth, numYear } from '../../../lib/date-util';
import FormCari from '../../../components/ui/form/form-cari';
import Spp from '../../../components/user/spp/spp';
import { Text } from '@chakra-ui/react';

function SppCekPage() {
  const [sppDate, setSppDate] = useState({
    year: numYear,
    month: numMonth,
  });

  const fetchUrl = `cek-spp?month=${sppDate.month}&year=${sppDate.year}&user=1&status=1`;

  const { data, isLoading, isError } = useFetchWithSwr(fetchUrl);

  return (
    <>
      <Text mb={5} fontWeight="semibold" fontSize="lg">
        {readableDate}
      </Text>

      <FormCari
        setTransDate={setSppDate}
        year={sppDate.year}
        month={sppDate.month}
        user="true"
      />

      {isError && errorText(isError.message)}
      {isLoading && spinner}
      {data && <Spp data={data.data} mutateUrl={fetchUrl} />}
    </>
  );
}

export default SppCekPage;
