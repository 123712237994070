import React from 'react';
import { Routes, Route } from 'react-router-dom';

import HomePage from './pages/home';
import LoginPage from './pages/auth/login';
import RegisterPage from './pages/auth/signup';
import ProtectAuth from './pages/auth/protect-auth';
import HalamanPendaftaran from './pages/daftar/daftar';
import MyContainer from './components/public-container/Container';
import Protected from './pages/protect-route/protected';
import Layout from './components/user/layout/layout';
import UserPage from './pages/user/dashboard';
import SppCekPage from './pages/user/spp-cek';
import MuridPage from './pages/admin/murid';
import DaftarWali from './pages/admin/wali';
import SppPage from './pages/admin/keuangan/spp';
import BayarSppPage from './pages/admin/keuangan/transaksi/spp';
import CekSppPage from './pages/admin/keuangan/cek-spp';
import PpdbPage from './pages/admin/ppdb';
import UnitPage from './pages/admin/unit';
import FaqPage from './pages/admin/faq';
import CarouselPage from './pages/admin/carousel';
import ProfilePage from './pages/user/setting/profile';
import ChangePasswordPage from './pages/user/setting/change-password';
import TestPage from './pages/TestPage';
import MuridDtPage from './pages/admin/murid/muridDt';
import ReportVaPage from './pages/admin/keuangan/spp/ReportVa';

function App() {
  return (
    <Routes>
      <Route element={<MyContainer />}>
        <Route path="/" element={<HomePage />} />
        <Route path="daftar/:unit/:id" element={<HalamanPendaftaran />} />
      </Route>

      <Route element={<ProtectAuth />}>
        <Route path="auth/login" element={<LoginPage />} />
        <Route path="auth/signup" element={<RegisterPage />} />
        <Route path="testva" element={<TestPage />} />
      </Route>

      <Route element={<Protected />}>
        <Route element={<Layout />}>
          <Route path="user/dashboard" element={<UserPage />} />
          <Route path="user/spp-cek" element={<SppCekPage />} />
          <Route path="user/setting/profile" element={<ProfilePage />} />
          <Route
            path="user/setting/change-password"
            element={<ChangePasswordPage />}
          />
          <Route path="admin/murid" element={<MuridPage />} />
          <Route path="admin/wali" element={<DaftarWali />} />
          <Route path="admin/keuangan/spp" element={<SppPage />} />
          <Route
            path="admin/keuangan/transaksi/spp"
            element={<BayarSppPage />}
          />
          <Route path="admin/keuangan/cek-spp" element={<CekSppPage />} />
          <Route path="admin/keuangan/report" element={<ReportVaPage />} />
          <Route path="admin/ppdb" element={<PpdbPage />} />
          <Route path="admin/unit" element={<UnitPage />} />
          <Route path="admin/faq" element={<FaqPage />} />
          <Route path="admin/carousel" element={<CarouselPage />} />
          <Route path="admin/testdt" element={<MuridDtPage />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
