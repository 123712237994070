import { useState, useContext } from 'react';
import { Formik, Form } from 'formik';
import { object } from 'yup';
import { Button, Flex, HStack } from '@chakra-ui/react';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import { MdAddCircle } from 'react-icons/md';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useSWRConfig } from 'swr';

import AlertContext from '../../../store/alert-context';
import { sendData } from '../../../lib/fetching';

function FormFormik({ tambah, edit, ComponentForm, schema, ...props }) {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [jenkel, setJenkel] = useState(props.jenkel?.toLowerCase());
  const { mutate } = useSWRConfig();

  const alertCtx = useContext(AlertContext);

  const initialValues = tambah ? { ...tambah } : { ...edit };

  const validationSchema = schema.validation;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={object(validationSchema)}
      onSubmit={(values, { setFieldError, resetForm }) => {
        setButtonLoading(true);

        const data = tambah
          ? schema.submitValues(values)
          : props.jenkel
          ? {
              id: initialValues.id,
              dataBaru: { ...schema.submitValues(values), jenkel },
            }
          : {
              dataLama: initialValues,
              dataBaru: schema.submitValues(values),
            };

        const method = tambah ? 'POST' : 'PUT';

        const url =
          props.spp || tambah
            ? schema.apiUrl
            : `${schema.apiUrl}/${data.id || initialValues.id}`;

        sendData(url, data.dataBaru || data, method)
          .then(_ => {
            setButtonLoading(false);
            resetForm();
            if (!props.spp) {
              mutate(props.ppdb ? props.ppdb : schema.mutateUrl);
              props.redirect([]);
            }

            if (props.unit) props.redirect(false);
            alertCtx.setAlert({
              active: true,
              status: 'success',
              title: 'sukses',
              description: `Berhasil ${tambah ? 'ditambahkan' : 'diubah'}`,
            });
          })
          .catch(e => {
            setButtonLoading(false);
            if (e.status === 403) {
              setFieldError('namaMurid', 'sudah bayar');
              setFieldError('spp', 'spp bulan ini sudah dibayar');
            }
            if (e.status === 422 && e.message.includes('already been taken')) {
              setFieldError('nama', 'unit ini sudah terdaftar');
            }
          });
      }}
    >
      <Form>
        <Flex direction="column" w="100%">
          <ComponentForm
            changeBg={props.changeBg}
            jenkel={jenkel}
            unit={props.unit}
            setJenkel={setJenkel}
            jenjang={initialValues.jenjang}
            kelas={props.kelas}
          />

          <HStack my={5}>
            <Button
              type="submit"
              variant="outline"
              rightIcon={tambah ? <MdAddCircle /> : <HiOutlinePencilAlt />}
              colorScheme="teal"
              w="70"
              h="12"
              fontSize="xl"
              isLoading={buttonLoading}
              loadingText="Loading..."
            >
              {props.spp ? 'Bayar' : tambah ? 'Tambah' : 'Edit'}
            </Button>
            {!props.spp && (
              <Button
                colorScheme="teal"
                variant="outline"
                rightIcon={<AiOutlineCloseCircle />}
                w="70"
                h="12"
                fontSize="xl"
                onClick={() => props.redirect([])}
              >
                Batal
              </Button>
            )}
          </HStack>
        </Flex>
      </Form>
    </Formik>
  );
}

export default FormFormik;
