import InputField from '../ui/input/input-field';
import SelectInput from '../ui/input/select';
import Wrapper from './input-wrapper';

const opt = ['Tutup', 'Buka'];

function InputUnit(props) {
  return (
    <Wrapper title="Data Unit" changeBg={props.changeBg}>
      <InputField
        id="nama"
        name="nama"
        label="Nama Unit"
        type="text"
        placeholder="tk / sd / smp"
      />
      <InputField
        id="desc"
        name="desc"
        label="Deskripsi"
        type="text"
        placeholder="deskripsi unit"
      />
      <InputField
        id="alamat"
        name="alamat"
        label="Alamat"
        type="text"
        placeholder="alamat unit"
      />
      <SelectInput
        id="status"
        name="status_ppdb"
        opt={opt}
        statusPpdb="true"
        label="Status PPDB"
        placeholder="-Pilih-"
      />
      <InputField
        id="uang_pendaftaran"
        name="uang_pendaftaran"
        label="Uang Pendaftaran"
        type="number"
        placeholder="Uang pendaftaran"
      />
      <InputField
        id="spp"
        name="spp"
        label="SPP"
        type="number"
        placeholder="spp / bulan"
      />
      <InputField id="grad" name="grad" label="Warna" type="text" />
    </Wrapper>
  );
}

export default InputUnit;
