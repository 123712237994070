import { useState } from 'react';
import {
  Button,
  Image,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { MdEdit, MdAdd, MdClose } from 'react-icons/md';

import AddCarousel from './AddCarousel';
import EditCarousel from './EditCarousel';
import { apiUrl } from '../../../lib/axios';

function MyCarousel({ data }) {
  const [showAddForm, setShowAddForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState({});

  return (
    <>
      {isEdit && (
        <EditCarousel data={editData} setIsEdit={setIsEdit} apiUrl={apiUrl} />
      )}

      {!isEdit && (
        <>
          <Button
            colorScheme="teal"
            rightIcon={!showAddForm ? <MdAdd /> : <MdClose />}
            onClick={() => setShowAddForm(!showAddForm)}
          >
            {!showAddForm ? 'Tambah' : 'Tutup'}
          </Button>

          {showAddForm && <AddCarousel showForm={setShowAddForm} />}

          <TableContainer whiteSpace="pre-wrap" maxWidth="100%">
            <Table variant="striped" colorScheme="gray">
              <TableCaption placement="top">Carousel Setting</TableCaption>
              <Thead>
                <Tr>
                  <Th>Judul</Th>
                  <Th>image</Th>
                  <Th>Aksi</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.map((info, idx) => (
                  <Tr key={idx}>
                    <Td>{info.title}</Td>
                    <Td>
                      <Image
                        boxSize="50px"
                        objectFit="cover"
                        src={`${apiUrl}/data_img/` + info.image}
                        alt={info.title}
                      />
                    </Td>
                    <Td>
                      <Button
                        variant="outline"
                        colorScheme="blue"
                        rightIcon={<MdEdit />}
                        onClick={() => {
                          setEditData(info);
                          setIsEdit(true);
                        }}
                      >
                        Edit
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}

export default MyCarousel;
