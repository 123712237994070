import { useState } from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';

import PisModal from '../../ui/modal/pis-modal';

function NonAktif({ row }) {
  const [siswa, setSiswa] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const changeStudentStatusHandler = (nama, id) => {
    setSiswa({ id, nama });
    onOpen();
  };
  return (
    <>
      <Button
        size="xs"
        variant="ghost"
        colorScheme="blue"
        onClick={() => changeStudentStatusHandler(row?.nama, row?.id)}
      >
        non-aktifkan
      </Button>

      <PisModal
        title={siswa?.nama ? `Non aktifkan ${siswa?.nama}` : 'Aktifkan ?'}
        isOpen={isOpen}
        id={siswa?.id}
        onClose={onClose}
      />
    </>
  );
}

export default NonAktif;
