import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
} from '@chakra-ui/react';
import React from 'react';

function Faq({ faqs }) {
  return (
    <>
      <Heading as="h2" textAlign="center" mb={5} id="faq">
        F.A.Q
      </Heading>
      <Accordion mb={5} defaultIndex={[0]} allowMultiple>
        {faqs?.map((faq, index) => (
          <AccordionItem borderRadius="xl" key={index}>
            <h2>
              <AccordionButton
                borderRadius="md"
                _expanded={{ bg: 'teal', color: 'white' }}
              >
                <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                  {faq?.title || 'Loading...'}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel py={5}>
              {faq?.content || 'Loading...'}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
}

export default Faq;
