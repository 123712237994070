import { Link as ReactLink } from 'react-router-dom';
import {
  Flex,
  Button,
  Heading,
  Text,
  Stack,
  Link,
  Icon,
  HStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
} from '@chakra-ui/react';
import { FaStar } from 'react-icons/fa';
import { MdLocationOn } from 'react-icons/md';

import Logo from '../../../ui/logo/logo';

function Jenjang(props) {
  return (
    <Flex
      direction={['column', null, null, 'row']}
      p={8}
      bgGradient={`linear(to-r,${props.grad}.50, #fff)`}
      border="1px"
      borderRadius="lg"
      borderColor={`${props.grad}.600`}
      justify="space-between"
      align="center"
    >
      <Logo width="200px" height="170px" />
      <Stack
        spacing={5}
        ml={[0, null, null, 10]}
        mr={[0, null, null, 10]}
        my={[10, null, null, 0]}
        textAlign={['center', null, null, 'left']}
      >
        <Heading size="lg">{`${props.nama?.toUpperCase()}`}</Heading>

        <HStack>
          <Icon as={FaStar} display={['none', null, null, 'inline']} />
          <Text>{props.desc}</Text>
        </HStack>
        <HStack>
          <Icon as={MdLocationOn} display={['none', null, null, 'inline']} />
          <Text>{props.alamat}</Text>
        </HStack>

        {props.ket && (
          <Accordion allowToggle>
            <AccordionItem>
              <h2>
                <AccordionButton
                  borderRadius="md"
                  bg={props.grad + '.400'}
                  _expanded={{ bg: props.grad + '.400', color: 'white' }}
                >
                  <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                    Detail
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Stack spacing={3} fontWeight="medium">
                  {props.ket.map((ket, idx) => (
                    <Text key={idx}>{`- ${ket}`}</Text>
                  ))}
                </Stack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        )}
      </Stack>
      <Link
        as={ReactLink}
        to={`/daftar/${props.nama}/${props.unitId}`}
        _hover={{ textDecoration: 'none' }}
      >
        <Button
          isDisabled={!+props.status}
          colorScheme={props.grad}
          // fontSize="lg"
          size="lg"
          // h="80px"
          borderRadius="xl"
          p={3}
        >
          {+props.status ? 'Daftar Sekarang' : 'Tutup'}
        </Button>
      </Link>
    </Flex>
  );
}

export default Jenjang;
