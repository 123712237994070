import { useContext } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { Button, Flex, Box, Link } from '@chakra-ui/react';

import Logo from '../../ui/logo/logo';
import MenuHamburger from './menu-hamburger';
import NavItem from './nav-item';
import AuthContext from '../../../store/auth-context';

function Header() {
  const authCtx = useContext(AuthContext);

  return (
    <>
      <Flex as="header" py={10} justify="space-between" align="center">
        <Flex
          // display={['none', null, null, 'flex']}
          as="nav"
          justify="space-between"
          align="center"
          w={[null, null, null, '60%', '40%']}
          fontSize="lg"
        >
          {/* <Logo height="100px" width="100px" /> */}
          <NavItem text={<Logo height="100px" width="95px" />} link="/" />
        </Flex>

        {!authCtx.isLoggedIn && (
          <Link as={ReactLink} to="/auth/login">
            <Button colorScheme="orange" size="lg" fontSize="xl">
              Log in
            </Button>
          </Link>
        )}

        {authCtx.isLoggedIn && (
          <Link as={ReactLink} to="/user/dashboard">
            <Button colorScheme="orange" size="lg" fontSize="lg">
              Dashboard
            </Button>
          </Link>
        )}

        {authCtx.isLoggedIn && (
          <Box display={[null, null, null, 'none']}>
            <MenuHamburger />
          </Box>
        )}
      </Flex>
    </>
  );
}

export default Header;
