import AddCarousel from './AddCarousel';

function EditCarousel({ data, setIsEdit, apiUrl }) {
  return (
    <AddCarousel
      carouselId={data.id}
      initTitle={data.title}
      img={data.image}
      url={apiUrl}
      isEdit={setIsEdit}
    />
  );
}

export default EditCarousel;
