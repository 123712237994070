import FormFormik from '../../../../components/ui/form/form-formik';
import { muridSchema } from '../../../../lib/formik-util';
import InputMurid from '../../../../components/pendaftaran/input-murid';
import {
  useFetchWithSwr,
  errorText,
  spinner,
} from '../../../../lib/fetching-util';

function EditPage(props) {
  const { data, isLoading, isError } = useFetchWithSwr('unit');

  const [siswa] = props.data;

  const kelas = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const initialValues = {
    namaMurid: siswa?.nama,
    alamatMurid: siswa?.alamat,
    tempatLahirMurid: siswa?.tmp_lahir,
    tglLahirMurid: siswa?.tgl_lahir,
    jenjang: siswa?.unit_id,
    id: siswa?.id,
    emailWali: siswa?.user_id,
    tglDaftar: siswa?.created_at,
    kelas: siswa?.kelas,
  };

  // console.log(siswa);
  return (
    <>
      {isError && errorText(isError)}
      {isLoading && spinner}
      {data && (
        <FormFormik
          edit={initialValues}
          schema={muridSchema}
          ComponentForm={InputMurid}
          jenkel={siswa?.jenkel}
          kelas={kelas}
          unit={data?.data}
          redirect={props.redirect}
          ppdb={props.ppdb}
          changeBg={true}
        />
      )}
    </>
  );
}

export default EditPage;
