import { Text, Stack } from '@chakra-ui/react';

function SppModalBody({ amount, ket, va, noVa, expDate, nama, appr }) {
  const date = new Date((expDate ?? va?.exp_va) + 'z');
  const timestamp = va?.tgl_bayar * 1000 || null;
  const tglBayar = new Date(timestamp) || null;

  return (
    <>
      <Stack fontWeight="medium" spacing={3} direction="column">
        <Text>{`Nama  : ${nama ?? va?.nama}`}</Text>
        <Text>Bank : Nagari</Text>
        <Text>{`no. Virtual Account : ${noVa ?? va?.no_va}`}</Text>
        <Text>{`Jumlah  : Rp.  ${new Intl.NumberFormat('id-ID').format(
          amount
        )}`}</Text>
        <Text>{`Ket  : Pembayaran SPP bulan ${ket}`}</Text>
        {appr && (
          <Text>{`Tanggal Bayar : ${tglBayar.toLocaleDateString('id-ID', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })} Jam ${tglBayar.toLocaleTimeString('id-ID', {
            hour: '2-digit',
            minute: '2-digit',
          })}`}</Text>
        )}
        {!appr && (
          <Text>{`Silahkan dilakukan pembayaran sebelum tanggal ${date.toLocaleDateString(
            'id-ID',
            {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            }
          )} Jam ${date.toLocaleTimeString('id-ID', {
            hour: '2-digit',
            minute: '2-digit',
          })}`}</Text>
        )}
      </Stack>
    </>
  );
}

export default SppModalBody;
