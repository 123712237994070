import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
} from '@chakra-ui/react';
import { useEffect } from 'react';

function PisAlert({ status, title, desc, setAlert }) {
  useEffect(() => {
    const timer = setTimeout(() => {
      setAlert({
        active: false,
        status: '',
        title: '',
        description: '',
      });
    }, 3000);
    return () => clearTimeout(timer);
  });

  return (
    <Alert status={status}>
      <AlertIcon />
      <AlertTitle mr={2}>{title}</AlertTitle>
      <AlertDescription>{desc}</AlertDescription>
      <CloseButton
        position="absolute"
        right={['0', '0', '8px']}
        top={['0', '0', '8px']}
        onClick={() =>
          setAlert({
            active: false,
            status: '',
            title: '',
            description: '',
          })
        }
      />
    </Alert>
  );
}

export default PisAlert;
