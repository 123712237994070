import { useState, useContext, useEffect } from 'react';
import {
  Text,
  SimpleGrid,
  Box,
  Heading,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from '@chakra-ui/react';

import DashboardItem from './dashboard-item';
import AuthContext from '../../../store/auth-context';
import { sendData } from '../../../lib/fetching';
import { capitalizeFirst } from '../../../lib/string-util';

let totalSiswa = 0;

function Dashboard() {
  const [dataSiswa, setDataSiswa] = useState([]);
  const [total, setTotal] = useState(totalSiswa);

  const authCtx = useContext(AuthContext);

  useEffect(() => {
    if (!authCtx.isAdmin || dataSiswa.length === 0) return;

    totalSiswa = dataSiswa
      .map(data => +data.students_count)
      .reduce((acc, curr) => acc + curr, 0);

    setTotal(totalSiswa);
  }, [dataSiswa, authCtx.isAdmin]);

  useEffect(() => {
    if (!authCtx.isLoggedIn || !authCtx.isAdmin) return;

    sendData('unit/jumlah', null, 'GET').then(res => setDataSiswa(res.data));
    // .catch(e => console.error(e));
  }, [authCtx.isLoggedIn, authCtx.isAdmin]);

  useEffect(() => {
    if (!authCtx.isLoggedIn || authCtx.isAdmin) return;

    sendData('wali/anak', null, 'GET').then(res => setDataSiswa(res.data));
    // .catch(e => console.log(e));
  }, [authCtx.isLoggedIn, authCtx.isAdmin]);

  return (
    <>
      {authCtx.isAdmin && (
        <SimpleGrid minChildWidth="200px" spacing="40px" as="ul">
          <DashboardItem text="Total Siswa" sum={total} />
          {dataSiswa.map((data, idx) => (
            <DashboardItem
              key={idx}
              text={data.nama?.toUpperCase()}
              sum={data.students_count}
            />
          ))}
        </SimpleGrid>
      )}
      {!authCtx.isAdmin && (
        <Box mt={5}>
          <Heading>Selamat datang</Heading>
          <Text mt={5}>
            {`${authCtx.jenkel?.toLowerCase() === 'lk' ? 'Bapak' : 'Ibu'} `}
            <Text as="span" fontWeight="semibold" ml={1}>
              {capitalizeFirst(authCtx.nama)}
            </Text>
          </Text>

          <TableContainer>
            <Table variant="striped" colorScheme="gray">
              <TableCaption placement="top">
                <Heading variant="h2" my={5} fontSize="2xl">
                  Data Anak
                </Heading>
              </TableCaption>
              <Thead>
                <Tr>
                  <Th>nama</Th>
                  <Th>unit</Th>
                  <Th>kelas</Th>
                  <Th>alamat</Th>
                  <Th>tgl lahir</Th>
                  <Th>status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {dataSiswa.map((siswa, idx) => (
                  <Tr key={idx}>
                    <Td>{capitalizeFirst(siswa?.nama)}</Td>
                    <Td>{capitalizeFirst(siswa?.unit?.nama)}</Td>
                    <Td>{siswa?.kelas}</Td>
                    <Td>{capitalizeFirst(siswa?.alamat)}</Td>
                    <Td>{siswa?.tgl_lahir}</Td>
                    <Td>{siswa?.is_active ? 'Aktif' : 'Tidak Aktif'}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>

          {/* {info.map(item => (
            <Stack spacing={1} mt={3} key={item._id}>
              <Heading fontSize="xl" mt={3}>
                *{item.judul}
              </Heading>
              <Text mt={3}>{item.content}</Text>
            </Stack>
          ))} */}
        </Box>
      )}
    </>
  );
}

export default Dashboard;
