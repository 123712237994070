import { sendData } from '../lib/fetching';

function TestPage() {
  sendData('createva', { date: new Date().toISOString() }, 'POST')
    .then(res => console.log(res))
    .catch(err => console.log(err));
  return <div>TestPage</div>;
}

export default TestPage;
