import { Box, Image, Stack, Text } from '@chakra-ui/react';
import React from 'react';

function Contact() {
  return (
    <Box my={10} shadow="lg" p={5} bg="blackAlpha.100" id="kontak">
      <Stack direction="row" alignItems="center" spacing={5}>
        <Image
          borderRadius="full"
          boxSize="75px"
          width="95px"
          src="/images/lkhakim.png"
          alt="Lukmanul Hakim"
        />
        <Text fontSize="lg">Yayasan Lukmanul Hakim</Text>
      </Stack>

      <Box mt={5} fontSize="sm">
        <Text>Jl. Yos Sudarso</Text>
        {/* <Text>Kec. Padang Timur</Text> */}
        <Text>Desa gedang, Sungai Penuh</Text>
        <Text fontWeight="medium" mt={3}>
          Telp. 082388284915
        </Text>
        <Text fontWeight="medium">Whatsapp. 082388284915</Text>
      </Box>
    </Box>
  );
}

export default Contact;
