import { Box, Stack } from '@chakra-ui/react';
import { useState, useEffect, useContext } from 'react';
import DataTable from 'react-data-table-component';

import EditPage from '../../../pages/admin/wali/edit/edit';
import AlertContext from '../../../store/alert-context';
import PisAlert from '../../ui/alert/alert';
import Search from '../search-input';
import TableToolbar from '../../ui/table/table-toolbar';
import { columnsWali } from './kolom';

function WaliDataTable({ data, ...props }) {
  const [isEdit, setIsEdit] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const alertCtx = useContext(AlertContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isEdit]);

  const handleChange = ({ selectedRows }) => setSelectedItems(selectedRows);

  const paginationComponentOptions = {
    rowsPerPageText: 'Show',
    rangeSeparatorText: 'dari',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'All',
  };

  return (
    <>
      {isEdit.length !== 0 && (
        <EditPage
          data={isEdit}
          redirect={setIsEdit}
          ppdb={props.ppdb || false}
        />
      )}
      {isEdit.length === 0 && (
        <>
          {alertCtx.alert.active && (
            <PisAlert
              status={alertCtx.alert.status}
              title={alertCtx.alert.title}
              desc={alertCtx.alert.description}
              setAlert={alertCtx.setAlert}
            ></PisAlert>
          )}

          <Box bg="gray.200" shadow="md" borderRadius="2xl" p={5}>
            <Stack
              mb={5}
              spacing={10}
              direction={['column', null, null, 'row']}
            >
              <Search setSearch={props.setSearch} />
              {/* <Button
                colorScheme="teal"
                size="sm"
                variant="outline"
                onClick={exportFile}
                w={['90%', null, null, '30%']}
              >
                Export
              </Button> */}
            </Stack>

            <TableToolbar
              itemSelected={selectedItems}
              delItemSelected={setSelectedItems}
              // path={location.pathname}
              apiUrl={props.apiUrl}
              delUrl={props.delUrl}
              cekspp={props.cekspp}
              setAlert={alertCtx.setAlert}
              setIsEdit={setIsEdit}
            />

            {/* {isLoading && spinner} */}
            {/* {isError && errorText(isError.message)} */}
            {data && (
              <DataTable
                columns={columnsWali}
                data={data}
                selectableRows
                onSelectedRowsChange={handleChange}
                pagination
                paginationComponentOptions={paginationComponentOptions}
                highlightOnHover
                noDataComponent="Data tidak ditemukan"
                fixedHeader
                // title="Data Wali"
              />
            )}
          </Box>
        </>
      )}
    </>
  );
}

export default WaliDataTable;
