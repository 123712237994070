import { useState } from 'react';
import { useSWRConfig } from 'swr';
import { Flex, Text, HStack, Button, useToast } from '@chakra-ui/react';

import { sendData } from '../../../lib/fetching';

function Toolbar({ itemSelected, delItems, mutateUrl, detailHandler }) {
  const [buttonLoading, setButtonLoading] = useState(false);
  const { mutate } = useSWRConfig();

  const toast = useToast();

  if (itemSelected.length === 0) {
    return null;
  }

  const keterangan = itemSelected
    .map(item =>
      new Date(item.spp.spp_date).toLocaleDateString('id-ID', {
        month: 'short',
        year: 'numeric',
      })
    )
    .join(' ');

  const total = itemSelected.reduce(
    (total, item) => total + +item.siswa.unit.spp,
    0
  );

  const deleteHandler = () => delItems([]);

  const generateVa = () => {
    setButtonLoading(true);
    const siswa = itemSelected[0].siswa;
    const tagihan = itemSelected.map(siswa => siswa.spp.id);
    const ket = `pembayaran spp ${keterangan}`;

    sendData('createva', { siswa, tagihan, ket }, 'POST')
      .then(res => {
        detailHandler(null, null, null, null, res.data);
        mutate(mutateUrl);
      })
      .catch(_ =>
        toast({
          title: 'Maaf, sepertinya ada masalah. Silahkan dicoba lagi!',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      )
      .finally(() => {
        setButtonLoading(false);
        deleteHandler();
      });
  };

  return (
    <>
      <Flex
        justify="space-between"
        align="center"
        bg="gray.300"
        py={4}
        px={5}
        borderRadius="md"
        border="2px"
      >
        <Text>{`${
          itemSelected.length
        } bulan, Total: Rp. ${new Intl.NumberFormat('id-ID').format(
          total
        )}`}</Text>
        <HStack spacing={3} ml={[5, null, null, 1]}>
          <Button
            variant="outline"
            colorScheme="blue"
            isLoading={buttonLoading}
            size="sm"
            onClick={() => generateVa()}
          >
            Bayar
          </Button>

          <Button
            variant="outline"
            colorScheme="red"
            size="sm"
            onClick={deleteHandler}
          >
            Batal
          </Button>
        </HStack>
      </Flex>
    </>
  );
}

export default Toolbar;
