import Auth from '../../components/auth/auth';

function LoginPage() {
  return (
    <>
      <Auth isAuthLogin={true} />
    </>
  );
}

export default LoginPage;
