import { FormLabel, Radio, RadioGroup, Stack } from '@chakra-ui/react';
import InputField from '../ui/input/input-field';
import SelectInput from '../ui/input/select';
import Wrapper from './input-wrapper';

function InputMurid(props) {
  // console.log(props.unit);
  return (
    <Wrapper title="Data Murid" changeBg={props.changeBg}>
      <InputField
        id="namaMurid"
        name="namaMurid"
        label="Nama Lengkap"
        type="text"
        placeholder="nama lengkap murid"
      />
      <InputField
        id="alamatMurid"
        name="alamatMurid"
        label="Alamat"
        type="text"
        placeholder="alamat Lengkap murid"
      />
      <InputField
        id="tempatLahirMurid"
        name="tempatLahirMurid"
        label="Tempat Lahir"
        type="text"
        placeholder="kota kelahiran murid"
      />
      <InputField
        id="tglLahirMurid"
        name="tglLahirMurid"
        label="Tanggal Lahir (tgl/bln/tahun)"
        type="date"
      />
      {props.jenjang && (
        <SelectInput
          label="Jenjang"
          id="jenjang"
          name="jenjang"
          opt={props.unit}
          unit="true"
        />
      )}

      {props.kelas && (
        <SelectInput
          label="Kelas"
          id="kelas"
          name="kelas"
          opt={props.kelas}
          kelas="true"
        />
      )}

      <Stack direction="row" mt={5}>
        <FormLabel htmlFor="jenis-kelamin-murid">Jenis Kelamin</FormLabel>
        <RadioGroup
          id="jenis-kelamin-murid"
          onChange={props.setJenkel}
          value={props.jenkel}
        >
          <Stack direction="row">
            <Radio value="lk">Pria</Radio>
            <Radio value="pr">Wanita</Radio>
          </Stack>
        </RadioGroup>
      </Stack>
    </Wrapper>
  );
}

export default InputMurid;
