import { Button } from '@chakra-ui/react';
import { FaWhatsapp } from 'react-icons/fa';

import { onSendMsgHandler } from './sendMsg';
import { capitalizeFirst } from '../../../lib/string-util';
import Aktifasi from './Aktifasi';
import NonAktif from './NonAktif';

export const columnsPpdb = [
  {
    name: 'nama',
    selector: row => capitalizeFirst(row?.nama),
    sortable: true,
  },
  {
    name: 'no reg',
    selector: row => row?.no_register,
  },
  {
    name: 'kirim pesan',
    cell: row => (
      <Button
        colorScheme="whatsapp"
        size="sm"
        variant="link"
        rightIcon={<FaWhatsapp />}
        onClick={() => {
          onSendMsgHandler(
            row?.user,
            row?.nama,
            row?.unit?.uang_pendaftaran,
            row?.unit?.rek
          );
        }}
      >
        send
      </Button>
    ),
  },
  {
    name: 'aksi',
    cell: row => <Aktifasi row={row} />,
  },
  {
    name: 'unit',
    selector: row => capitalizeFirst(row?.unit?.nama),
    sortable: true,
    // allowOverflow: true,
  },
  {
    name: 'Tgl Daftar',
    selector: row => row?.created_at,
    sortable: true,
    format: ({ created_at: row }) => {
      return new Date(row)?.toLocaleDateString('id');
    },
    allowOverflow: true,
  },
  {
    name: 'kelas',
    selector: row => row?.kelas,
    sortable: true,
    center: true,
  },
  {
    name: 'Tgl Lahir',
    selector: row => row?.tgl_lahir,
    sortable: true,
    format: ({ tgl_lahir: row }) => {
      return new Date(row)?.toLocaleDateString('id');
    },
  },
  {
    name: 'alamat',
    selector: row => capitalizeFirst(row?.alamat),
    sortable: true,
    // wrap: true,
  },
  {
    name: 'Hp/WA',
    selector: row => row?.user?.whatsapp,
    format: ({ user }) => `0${user?.whatsapp}`,
    allowOverflow: true,
  },
];

export const columnsAktif = [
  {
    name: 'nama',
    selector: row => capitalizeFirst(row?.nama),
    sortable: true,
  },
  {
    name: 'no reg',
    selector: row => row?.no_register,
  },
  {
    name: 'unit',
    selector: row => capitalizeFirst(row?.unit?.nama),
    sortable: true,
    allowOverflow: true,
  },
  {
    name: 'kelas',
    selector: row => row?.kelas,
    sortable: true,
    center: true,
  },
  {
    name: 'status',
    selector: row => (row?.is_active ? 'aktif' : 'tidak aktif'),
    sortable: true,
  },
  {
    name: 'aksi',
    cell: row => <NonAktif row={row} />,
  },
  {
    name: 'Tgl Daftar',
    selector: row => row?.created_at,
    sortable: true,
    format: ({ created_at: row }) => {
      return new Date(row)?.toLocaleDateString('id');
    },
  },
  {
    name: 'Tgl Lahir',
    selector: row => row?.tgl_lahir,
    sortable: true,
    format: ({ tgl_lahir: row }) => {
      return new Date(row)?.toLocaleDateString('id');
    },
  },
  {
    name: 'alamat',
    selector: row => capitalizeFirst(row?.alamat),
    sortable: true,
    // wrap: true,
  },
  {
    name: 'Hp/WA',
    selector: row => row?.user?.whatsapp,
    format: ({ user }) => `0${user?.whatsapp}`,
    allowOverflow: true,
  },
];
