import { string, number, date } from 'yup';

export const kategoriSchema = {
  validation: {
    nama: string().required('wajib diisi'),
    tipe: string().required('wajib diisi'),
    kode: string().required('wajib diisi'),
  },
  submitValues: values => ({
    name: values.nama,
    type: values.tipe,
    kode: values.kode,
    id: values.id,
  }),
  redirectUrl: '/admin/keuangan/kategori',
  apiUrl: '/api/data/kategori',
};

export const transaksiSchema = {
  validation: {
    date: date().required('wajib diisi'),
    transaksi: string().required('wajib diisi'),
    jumlah: number().integer('harus bilangan bulat').required('wajib diisi'),
  },
  submitValues: values => ({
    id: values.id,
    date: values.date,
    transaksi: values.transaksi,
    jumlah: values.jumlah,
    ket: values.ket,
  }),
  redirectUrl: '/admin/keuangan/transaksi',
  apiUrl: '/api/data/transaksi',
};

export const sppSchema = {
  validation: {
    // date: date().required('wajib diisi'),
    namaMurid: string().required('wajib dipilih'),
    spp: string().required('wajib dipilih'),
    jumlah: number().integer('harus bilangan bulat').required('wajib diisi'),
  },
  submitValues: values => ({
    // date: values.date,
    namaMurid: values.namaMurid,
    spp: values.spp,
    jumlah: values.jumlah,
    ket: values.ket,
  }),
  redirectUrl: '/admin/keuangan/transaksi/spp',
  apiUrl: 'sppcek',
};

export const muridSchema = {
  validation: {
    namaMurid: string().required('wajib diisi'),
    alamatMurid: string().required('wajib diisi'),
    tglLahirMurid: string().required('wajib diisi'),
    tempatLahirMurid: string().required('wajib diisi'),
    jenjang: string().required('wajib diisi'),
  },
  submitValues: values => ({
    nama: values.namaMurid?.trim().toLowerCase(),
    alamat: values.alamatMurid?.trim().toLowerCase(),
    tmp_lahir: values.tempatLahirMurid?.trim().toLowerCase(),
    tgl_lahir: values.tglLahirMurid,
    unit_id: values.jenjang,
    kelas: values.kelas,
    // user_id: values.emailWali,
    // tglDaftar: values.tglDaftar,
  }),
  redirectUrl: '/admin/murid',
  apiUrl: 'student',
  mutateUrl: 'student?search=',
};

export const waliSchema = {
  validation: {
    namaWali: string().required('wajib diisi'),
    alamatWali: string().required('wajib diisi'),
    tglLahirWali: string().required('wajib diisi'),
    noWA: string().required('wajib diisi'),
  },
  submitValues: values => ({
    nama: values.namaWali?.trim().toLowerCase(),
    alamat: values.alamatWali?.trim().toLowerCase(),
    tgl_lahir: values.tglLahirWali,
    whatsapp:
      values.noWA?.charAt(0) === '0' ? values.noWA.substring(1) : values.noWA,
  }),
  redirectUrl: '/admin/wali',
  apiUrl: 'wali',
  mutateUrl: 'wali?search=',
};

export const unitSchema = {
  validation: {
    nama: string().required('wajib diisi'),
    desc: string().required('wajib diisi'),
    alamat: string().required('wajib diisi'),
    status_ppdb: number().required('wajib diisi'),
    uang_pendaftaran: number().required('wajib diisi'),
    // spp: string(),
    grad: string().required('wajib diisi'),
  },
  submitValues: values => ({
    nama: values.nama?.trim(),
    desc: values.desc?.trim(),
    alamat: values.alamat?.trim(),
    status_ppdb: +values.status_ppdb,
    uang_pendaftaran: +values.uang_pendaftaran,
    spp: values.spp,
    grad: values.grad?.trim().toLowerCase(),
  }),
  redirectUrl: '/admin/unit',
  apiUrl: 'unit',
  mutateUrl: 'unit',
};

export const faqSchema = {
  validation: {
    title: string().required('wajib diisi'),
    content: string().required('wajib diisi'),
  },
  submitValues: values => ({
    title: values.title?.trim(),
    content: values.content?.trim(),
  }),
  redirectUrl: '/admin/faq',
  apiUrl: 'faq',
  mutateUrl: 'faqs',
};
