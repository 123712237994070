import { useState } from 'react';
import { useSWRConfig } from 'swr';
import { Button, FormControl, Input } from '@chakra-ui/react';

import {
  errorText,
  useFetchWithSwr,
  spinner,
} from '../../../../lib/fetching-util';
import Spp from '../../../../components/admin/keuangan/spp';
import { sendData } from '../../../../lib/fetching';

function SppPage() {
  const [aktifkanForm, setAktifkanForm] = useState(false);
  const [dateInput, setDateInput] = useState('');
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const { data, isLoading, isError } = useFetchWithSwr('spp');

  const { mutate } = useSWRConfig();

  window.scrollTo(0, 0);

  const aktifkanHandler = () => {
    if (!dateInput) return;

    setIsButtonLoading(true);
    const [year, month] = dateInput.split('-');

    // console.log({ date: `${+month}-01-${year}` });

    sendData('spp', { date: `${+month}-01-${year}` }, 'POST')
      .then(res => {
        mutate('spp');
        setIsButtonLoading(false);
        setAktifkanForm(false);
      })
      .catch(e => {
        setIsButtonLoading(false);
        // console.error(e);
      });
  };

  return (
    <>
      <Button
        mb={5}
        colorScheme="teal"
        onClick={() => setAktifkanForm(!aktifkanForm)}
      >
        Aktifkan SPP
      </Button>

      {aktifkanForm && (
        <FormControl mb={8}>
          {/* <FormLabel>Bulan</FormLabel> */}
          <Input
            value={dateInput}
            type="date"
            w="50%"
            onChange={e => setDateInput(e.target.value)}
          />
          <Button
            ml={3}
            colorScheme="teal"
            variant="outline"
            onClick={aktifkanHandler}
            isDisabled={!dateInput}
            isLoading={isButtonLoading}
          >
            aktifkan
          </Button>
        </FormControl>
      )}

      {isError && errorText(isError)}
      {isLoading && spinner}
      {data && <Spp data={data} />}
    </>
  );
}

export default SppPage;
