import { Fragment } from 'react';
import {
  Select,
  FormControl,
  FormLabel,
  FormHelperText,
} from '@chakra-ui/react';
import { useField } from 'formik';

import classes from './option.module.css';
import { readableDateForSpp } from '../../../lib/date-util';

function SelectInput({ label, ...props }) {
  const [field, meta] = useField(props);

  let isInvalid = meta.touched && meta.error;

  const mbSelect = !isInvalid ? { mb: 3 } : null;

  return (
    <FormControl>
      <FormLabel
        fontSize="md"
        fontWeight="light"
        htmlFor={props.id || props.name}
      >
        {label}
      </FormLabel>

      <Select
        placeholder="- Pilih -"
        maxW={['100%', '100%', '90%']}
        size="lg"
        {...mbSelect}
        isInvalid={isInvalid}
        errorBorderColor="red.300"
        {...field}
        {...props}
      >
        {props.opt?.map((opt, idx) => (
          <Fragment key={Math.random()}>
            {!props.spp && !props.statusPpdb && (
              <option
                value={
                  props.unit
                    ? opt.id
                    : props.kelas
                    ? opt
                    : props.sppaktif
                    ? opt.id
                    : `${opt.name}-${opt.type}-${opt.kode}`
                }
                className={classes.option}
              >
                {props.unit
                  ? opt.nama
                  : props.kelas
                  ? opt
                  : props.sppaktif
                  ? `${readableDateForSpp(opt.spp_date)}`
                  : `${opt.name}`}
              </option>
            )}

            {props.statusPpdb && (
              <option value={idx} className={classes.option}>
                {opt}
              </option>
            )}

            {props.spp && (
              <option
                value={`${opt.id}`}
                className={classes.option}
              >{`${opt.nama}-kls ${opt.kelas} ${opt.unit.nama}`}</option>
            )}

            {(props.tahun || props.bulan) && (
              <option
                key={idx}
                value={props.tahun ? opt : idx}
                className={classes.option}
              >
                {opt}
              </option>
            )}
          </Fragment>
        ))}
      </Select>
      {isInvalid && (
        <FormHelperText mb={3} color="red.300">
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default SelectInput;
