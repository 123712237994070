import { useState } from 'react';

// import Murid from '../../../components/admin/murid/murid';
import MuridDataTable from '../../../components/admin/murid/MuridDataTable';
import { useFetchWithSwr, errorText } from '../../../lib/fetching-util';

function MuridPage() {
  const [search, setSearch] = useState('');
  const { data, isLoading, isError } = useFetchWithSwr(
    `student?search=${search}`
  );

  // arrMurid = data.data
  return (
    <MuridDataTable
      aktif="true"
      data={data?.data}
      setSearch={setSearch}
      isLoading={isLoading}
      isError={isError}
      delUrl="student"
      errorText={errorText}
      // spinner={spinner}
      // apiUrl={mutateUrl}
    />

    // <Murid
    //   data={data?.data}
    //   aktif="true"
    //   setSearch={setSearch}
    //   loading={isLoading}
    //   error={isError}
    // />
  );
}

export default MuridPage;
