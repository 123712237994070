import { useContext, useEffect } from 'react';
import { Heading, Text, Avatar, Flex, Stack, Box } from '@chakra-ui/react';

import AuthContext from '../../../store/auth-context';

function Profile() {
  const { nama, email, whatsapp, alamat } = useContext(AuthContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box w="100%">
      <Box
        w={['100%', null, null, '90%']}
        bg="gray.600"
        p={10}
        color="white"
        borderRadius="lg"
        shadow="lg"
      >
        <Flex
          w={['100%', null, null, '80%']}
          justify="space-around"
          align="center"
          direction={['column', null, null, 'row']}
        >
          <Flex direction="column" align="center">
            <Avatar size="lg" name={nama} bg="teal.500" />
            <Text fontSize="sm" mt={2} display={['none', null, null, 'block']}>
              {nama}
            </Text>
          </Flex>
          <Stack spacing={3} textAlign={['center', null, null, 'left']}>
            <Heading mt={[3, null, null, -3]}>{nama}</Heading>
            <Text>{email}</Text>
            <Text>{`HP / Whatsapp : 0${whatsapp}`}</Text>
            <Text>{`Alamat   : ${alamat}`}</Text>
          </Stack>
        </Flex>
      </Box>
      {/* <Text my={5}>{`HP / Whatsapp : 0${otherData.whatsapp}`}</Text>
      <Text my={5}>{`Alamat   : ${otherData.alamat}`}</Text>
      <Text my={5}>{`Tgl Lahir   : ${new Date(
        otherData.tgl_lahir
      ).toLocaleDateString('id-ID', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })}`}</Text> */}
    </Box>
  );
}

export default Profile;
