import {
  useFetchWithSwr,
  spinner,
  errorText,
} from '../../../lib/fetching-util';

import MyCarousel from '../../../components/admin/carousel';

function CarouselPage() {
  const { data, isLoading, isError } = useFetchWithSwr('carousels');

  return (
    <>
      {isError && errorText(isError.message)}
      {isLoading && spinner}
      {data && <MyCarousel data={data?.data} />}
    </>
  );
}

export default CarouselPage;
