import { useState, useEffect } from 'react';
import { Button } from '@chakra-ui/react';

function PilihButton({ spp, siswa, selectedItem, setSelectedItem }) {
  const [isDisabled, setIsDisabled] = useState(false);

  const clickHandler = () => {
    setSelectedItem(prev => [...prev, { spp, siswa }]);
    setIsDisabled(true);
  };

  useEffect(() => {
    if (!isDisabled || selectedItem.length > 0) return;

    setIsDisabled(false);
  }, [isDisabled, selectedItem]);

  return (
    <Button
      variant="outline"
      colorScheme="teal"
      isDisabled={
        isDisabled ||
        (selectedItem.length > 0 && selectedItem[0]?.siswa.id !== siswa.id)
      }
      onClick={clickHandler}
    >
      Pilih
    </Button>
  );
}

export default PilihButton;
