import React, { useState, useEffect } from 'react';

import { spinner } from '../lib/fetching-util';
import { sendData } from '../lib/fetching';

let logoutTimer;

const AuthContext = React.createContext({
  token: '',
  isLoggedIn: false,
  id: '',
  jenkel: '',
  isAdmin: false,
  name: '',
  alamat: '',
  whatsapp: '',
  email: '',
  login: ({ token, user }) => {},
  logout: () => {},
});

const remainingTime = expTime => {
  const currTime = new Date().getTime();
  const remainingDuration = expTime - currTime;

  return remainingDuration;
};

const getUserData = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const isAdmin = localStorage.getItem('admin');
  const expTime = localStorage.getItem('expTime');

  const remainingDuration = remainingTime(expTime);

  if (remainingDuration <= 60000) return null;

  return {
    user,
    isAdmin,
    duration: remainingDuration,
  };
};

export const AuthContextProvider = props => {
  const userData = getUserData();

  const [user, setUser] = useState(userData?.user || null);
  const [isAdmin, setIsAdmin] = useState(userData?.isAdmin || null);
  const [isLoading, setIsLoading] = useState(true);

  const userIsLoggedIn = !!user;
  const userIsAdmin = !!(+isAdmin && +isAdmin === 503);
  const id = user?.id;
  const jenkel = user?.jenkel;
  const nama = user?.nama;
  const email = user?.email;
  const alamat = user?.alamat;
  const whatsapp = user?.whatsapp;

  const logoutHandler = () => {
    sendData('logout', null, 'POST')
      // .then(_ => {
      //   if (logoutTimer) clearTimeout(logoutTimer);
      //   setIsAdmin(null);
      //   setUser(null);
      //   localStorage.removeItem('user');
      //   localStorage.removeItem('admin');
      //   localStorage.removeItem('expTime');
      // })
      .finally(() => {
        if (logoutTimer) clearTimeout(logoutTimer);
        setIsAdmin(null);
        setUser(null);
        localStorage.removeItem('user');
        localStorage.removeItem('admin');
        localStorage.removeItem('expTime');
      });
  };

  const loginHandler = ({ user, isAdmin }) => {
    const expTime = 7200000;

    const remainingDuration = remainingTime(new Date().getTime() + expTime);

    localStorage.setItem('expTime', new Date().getTime() + expTime);
    localStorage.setItem('admin', isAdmin);
    localStorage.setItem('user', JSON.stringify(user));
    setIsAdmin(isAdmin);
    setUser(user);

    logoutTimer = setTimeout(logoutHandler, remainingDuration);
  };

  useEffect(() => {
    if (userData) {
      logoutTimer = setTimeout(logoutHandler, userData.duration);
    }
    setIsLoading(false);
  }, [userData]);

  const contextValue = {
    isLoggedIn: userIsLoggedIn,
    isAdmin: userIsAdmin,
    id,
    jenkel,
    nama,
    email,
    alamat,
    whatsapp,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {isLoading && spinner}
      {!isLoading && props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
