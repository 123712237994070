import { useState } from 'react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Heading,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { string, object } from 'yup';
import InputMurid from './input-murid';
import InputWali from './input-wali';
import PisModal from '../ui/modal/pis-modal';
// import { capitalizeFirst } from '../../lib/string-util';
import { sendData } from '../../lib/fetching';

function FormPendaftaran(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [jenkelWali, setJenkelWali] = useState('lk');
  const [jenkelMurid, setJenkelMurid] = useState('lk');
  const [modalTitle, setModalTitle] = useState('');
  const [modalBodyText, setModalBodyText] = useState({});
  const [feedbackData, setFeedbackData] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialValues = {
    namaWali: '',
    namaMurid: '',
    alamatWali: '',
    alamatMurid: '',
    tglLahirWali: '',
    tglLahirMurid: '',
    tempatLahirMurid: '',
    noWA: '',
    email: '',
  };

  const validationSchema = {
    email: string().email('Email tidak valid!').required('email harus diisi'),
    namaWali: string().required('nama harus diisi'),
    namaMurid: string().required('nama harus diisi'),
    alamatWali: string().required('alamat harus diisi'),
    alamatMurid: string().required('alamat harus diisi'),
    tempatLahirMurid: string().required('tempat lahir harus diisi'),
    tglLahirMurid: string().required('tgl lahir harus diisi'),
    tglLahirWali: string().required('tgl lahir harus diisi'),
    noWA: string().required('no hp/ wa harus diisi'),
  };

  return (
    <>
      <Box shadow="xl" bg="white" borderRadius="xl" overflowX="hidden" my={5}>
        <Box p={5} bg="teal.600" color="white">
          <Heading>{`Form Pendaftaran ${props.jenjang}`}</Heading>
        </Box>

        <Formik
          initialValues={initialValues}
          validationSchema={object(validationSchema)}
          onSubmit={async (values, { setFieldValue, resetForm }) => {
            const data = {
              dataWali: {
                email: values.email.toLowerCase(),
                nama: values.namaWali.toLowerCase(),
                alamat: values.alamatWali.toLowerCase(),
                tglLahir: values.tglLahirWali,
                noWA: `${values.noWA}`,
                jenkel: jenkelWali,
              },
              dataMurid: {
                nama: values.namaMurid.toLowerCase(),
                alamat: values.alamatMurid.toLowerCase(),
                tglLahir: values.tglLahirMurid,
                tempatLahir: values.tempatLahirMurid.toLowerCase(),
                unit: props.id,
                namaUnit: props.jenjang,
                jenkel: jenkelMurid,
              },
            };

            setIsLoading(true);
            try {
              const res = await sendData('ppdb/daftar', data);
              // console.log(res);
              setIsLoading(false);
              setModalTitle('Sukses');
              setModalBodyText(res.message || res.title);
              setFeedbackData(res.data || '');
              onOpen();
              resetForm();
            } catch (error) {
              // console.log(error);
              setIsLoading(false);
              setModalBodyText(error.message);
              setModalTitle('Gagal');
              onOpen();
            }
          }}
        >
          <Form>
            <Flex
              direction={['column', null, null, 'row']}
              align={['center', null, null, 'flex-start']}
              justify="space-around"
              w="100%"
            >
              <InputWali jenkel={jenkelWali} setJenkel={setJenkelWali} />
              <InputMurid jenkel={jenkelMurid} setJenkel={setJenkelMurid} />
            </Flex>

            <VStack my={5}>
              <Button
                type="submit"
                rightIcon={<ArrowForwardIcon />}
                colorScheme="teal"
                variant="solid"
                w="50"
                h="16"
                fontSize="xl"
                isLoading={isLoading}
                loadingText="Mengirim data..."
              >
                {`Daftar ${props.jenjang}`}
              </Button>
            </VStack>
          </Form>
        </Formik>
      </Box>
      {isOpen && (
        <PisModal
          isOpen={isOpen}
          title={modalTitle}
          body={modalBodyText}
          data={feedbackData}
          onClose={onClose}
          openWA="true"
        />
      )}
    </>
  );
}

export default FormPendaftaran;
