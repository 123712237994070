import { Divider } from '@chakra-ui/react';

import Header from '../../components/home/header/header';
import Footer from '../../components/home/footer/footer';
import MainContent from '../../components/home/main-content/main-content';

export default function HomePage() {
  return (
    <>
      <Header />
      <MainContent />
      <Divider />
      <Footer />
    </>
  );
}
