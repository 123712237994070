import Hero from './hero/hero';
import Content from './content/content';
import Marquee from 'react-fast-marquee';
import { Center, Heading, Stack } from '@chakra-ui/react';

import CardCaraousel from './card/CardCaraousel';
import { useFetchWithSwr, spinner } from '../../../lib/fetching-util';
import { apiUrl } from '../../../lib/axios';

function MainContent() {
  const { data, isLoading, isError } = useFetchWithSwr('carousels');

  return (
    <main>
      <Hero />
      <Marquee
        speed={30}
        pauseOnHover
        style={{ marginTop: '-100px', marginBottom: '30px' }}
      >
        <Center>
          {isLoading && spinner}
          {!isLoading && !isError && (
            <Stack direction="row" spacing={10}>
              {data?.data.map((slide, idx) => (
                <CardCaraousel
                  key={idx}
                  url={apiUrl + '/data_img/' + slide.image}
                  alt={slide.title}
                />
              ))}
            </Stack>
          )}
        </Center>
      </Marquee>

      <Marquee speed={50} style={{ marginTop: '20px', height: '100px' }}>
        <Center mx={5} color="blue.500">
          <Stack direction="row">
            <Heading as="h2" size={['md', null, null, 'xl']}>
              Segera daftarkan putra-putri Bapak / Ibu. Pendaftaran akan ditutup
              jika kuota telah terpenuhi.
            </Heading>
            {/* <Heading as="h2" size={['md', null, null, 'xl']}>
              Pendaftaran akan ditutup jika kuota telah terpenuhi.
            </Heading> */}
          </Stack>
        </Center>
      </Marquee>
      <Content />
    </main>
  );
}

export default MainContent;
