import {
  FormLabel,
  Textarea,
  FormHelperText,
  FormControl,
} from '@chakra-ui/react';
import { useField } from 'formik';

function TextField({ label, ...props }) {
  const [field, meta] = useField(props);

  const isInvalid = meta.touched && meta.error;

  const mbInput = !isInvalid ? { mb: 3 } : null;

  // const inputWidth = props.auth
  //   ? { maxW: ['100%', '100%', '70%'] }
  //   : { maxW: ['100%', '100%', '90%'] };

  return (
    <FormControl>
      <FormLabel
        fontSize="md"
        fontWeight="light"
        htmlFor={props.id || props.name}
      >
        {label}
      </FormLabel>
      <Textarea
        {...field}
        {...props}
        size="lg"
        focusBorderColor="teal.500"
        isInvalid={isInvalid}
        {...mbInput}
      />
      {isInvalid && (
        <FormHelperText mb={3} color="red.300">
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default TextField;
