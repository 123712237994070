import { Skeleton, Stack } from '@chakra-ui/react';

import Jenjang from './jenjang';
import Faq from '../Faq';
import Contact from '../Contact';
import { useFetchWithSwr } from '../../../../lib/fetching-util';

function Content() {
  const { data, isLoading, isError } = useFetchWithSwr('units');
  const {
    data: dataFaq,
    isLoading: isLoadingFaq,
    isError: isErrorFaq,
  } = useFetchWithSwr('faqs');

  return (
    <>
      {isLoading && (
        <Stack spacing={8} mt={5} mb={10}>
          <Skeleton borderRadius="lg" height="220px" isLoaded={!isLoading} />
          <Skeleton borderRadius="lg" height="220px" isLoaded={!isLoading} />
          <Skeleton borderRadius="lg" height="220px" isLoaded={!isLoading} />
        </Stack>
      )}

      {data && !isError && (
        <Stack spacing={8} mt={5} mb={10} id="unit-pertiwi">
          {data.data.map((u, idx) => (
            <Jenjang
              key={idx}
              grad={u.grad}
              nama={u.nama}
              alamat={u.alamat}
              status={u.status_ppdb}
              desc={u.desc}
              unitId={u.id}
              // ket={JSON.parse(u.keterangan)}
            />
          ))}
        </Stack>
      )}

      {isLoadingFaq && (
        <Skeleton borderRadius="lg" height="220px" isLoaded={!isLoadingFaq} />
      )}
      {dataFaq && !isErrorFaq && <Faq faqs={dataFaq.data} />}
      <Contact />
    </>
  );
}

export default Content;
