import FormFormik from '../../../../components/ui/form/form-formik';
import { unitSchema } from '../../../../lib/formik-util';
import InputUnit from '../../../../components/pendaftaran/input-unit';

function EditPage({ data, redirect }) {
  const initialValues = {
    nama: data[0]?.nama,
    desc: data[0]?.desc,
    alamat: data[0]?.alamat,
    status_ppdb: data[0]?.status_ppdb,
    spp: data[0]?.spp,
    uang_pendaftaran: data[0].uang_pendaftaran,
    grad: data[0]?.grad,
    id: data[0]?.id,
  };

  return (
    <>
      {data.length === 0 && <div>Tidak ada data</div>}
      {data.length !== 0 && (
        <FormFormik
          edit={initialValues}
          schema={unitSchema}
          ComponentForm={InputUnit}
          changeBg={true}
          redirect={redirect}
        />
      )}
    </>
  );
}

export default EditPage;
