import { useState } from 'react';
import { useSWRConfig } from 'swr';
import {
  Flex,
  Text,
  HStack,
  Icon,
  Tooltip,
  useDisclosure,
  Container,
} from '@chakra-ui/react';
import { MdEdit } from 'react-icons/md';
import { AiOutlineDelete } from 'react-icons/ai';

import PisModal from '../modal/pis-modal';
import { sendData } from '../../../lib/fetching';

function TableToolbar({
  setIsEdit,
  itemSelected,
  path,
  apiUrl,
  setAlert,
  ...props
}) {
  const [buttonLoading, setButtonLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { mutate } = useSWRConfig();

  if (itemSelected.length === 0) {
    return null;
  }

  // console.log(itemSelected);

  const deleteHandler = () => {
    setButtonLoading(true);
    const ids = itemSelected.map(item => +item.id);

    sendData(`${props.delUrl}/${ids}`, null, 'DELETE').then(res => {
      setButtonLoading(false);

      if (props.delItemSelected) props.delItemSelected([]);

      props.delUrl === 'unit' ? mutate(props.delUrl) : mutate(apiUrl);

      // eslint-disable-next-line no-unused-expressions
      setAlert({
        active: true,
        status: 'success',
        title: 'sukses',
        description: 'Berhasil dihapus',
      });
      onClose();
    });
  };

  return (
    <Container size="xl" mb={1}>
      <Flex
        justify="space-between"
        align="center"
        bg="gray.300"
        py={4}
        px={5}
        borderRadius="md"
        pos="sticky"
        top="0"
        zIndex={2}
      >
        <Text>{`${itemSelected.length} terpilih`}</Text>
        <HStack spacing={5}>
          {itemSelected.length < 2 && itemSelected[0].kode !== 'SPP' && (
            <Tooltip label="Edit">
              <span>
                <Icon
                  as={MdEdit}
                  w={6}
                  h={6}
                  onClick={() => {
                    if (props.delItemSelected) props.delItemSelected([]);
                    setIsEdit(itemSelected);
                  }}
                />
              </span>
            </Tooltip>
          )}

          {!itemSelected[0].email && !props.cekspp && (
            <Tooltip label="Hapus">
              <span onClick={onOpen}>
                <Icon as={AiOutlineDelete} w={6} h={6} />
              </span>
            </Tooltip>
          )}
        </HStack>
      </Flex>
      {isOpen && (
        <PisModal
          isOpen={isOpen}
          title="Yakin mau hapus"
          body={`${itemSelected.length} item akan terhapus`}
          action={deleteHandler}
          btnLoading={buttonLoading}
          onClose={onClose}
        />
      )}
    </Container>
  );
}

export default TableToolbar;
