import FormFormik from '../../../../components/ui/form/form-formik';
import { faqSchema } from '../../../../lib/formik-util';
import InputFaq from '../../../../components/admin/faq/InputFaq';

function EditPage({ data, redirect }) {
  const initialValues = {
    id: data[0]?.id,
    title: data[0]?.title,
    content: data[0]?.content,
  };

  return (
    <>
      {data.length === 0 && <div>Tidak ada data</div>}
      {data.length !== 0 && (
        <FormFormik
          edit={initialValues}
          schema={faqSchema}
          ComponentForm={InputFaq}
          changeBg={true}
          redirect={redirect}
        />
      )}
    </>
  );
}

export default EditPage;
