import { useState, useEffect, useCallback, useContext } from 'react';
import DataTable from 'react-data-table-component';
import { Box, Button, SimpleGrid, Stack } from '@chakra-ui/react';
import { utils, writeFileXLSX } from 'xlsx';

import { columnsPpdb, columnsAktif } from './kolom';
import EditPage from '../../../pages/admin/murid/edit/edit';
import Search from '../search-input';
import { arrangeData } from './helper/arrangeData';
import PisAlert from '../../ui/alert/alert';
import AlertContext from '../../../store/alert-context';
import TableToolbar from '../../ui/table/table-toolbar';

function MuridDataTable({
  data,
  isError,
  isLoading,
  spinner,
  errorText,
  ...props
}) {
  const [isEdit, setIsEdit] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const alertCtx = useContext(AlertContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isEdit]);

  const arrangedData = arrangeData(data || []);

  const exportFile = useCallback(() => {
    const ws = utils.json_to_sheet(arrangedData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'data');
    writeFileXLSX(wb, 'data.xlsx');
  }, [arrangedData]);

  const handleChange = ({ selectedRows }) => setSelectedItems(selectedRows);

  const paginationComponentOptions = {
    rowsPerPageText: 'Show',
    rangeSeparatorText: 'dari',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'All',
  };

  return (
    <>
      {isEdit.length !== 0 && (
        <EditPage
          data={isEdit}
          redirect={setIsEdit}
          ppdb={props.ppdb || false}
        />
      )}
      {isEdit.length === 0 && (
        <>
          {props.jmlPerUnit && (
            <SimpleGrid
              // mt={-20}
              mb={5}
              bg="gray.200"
              columns={{ sm: 2, md: 4 }}
              spacing="8"
              p="3"
              textAlign="center"
              rounded="lg"
            >
              {props.jmlPerUnit.map((unit, idx) => (
                <Box
                  key={idx}
                  boxShadow="md"
                  rounded="md"
                  bg="white"
                >{`${unit.nama} : ${unit.students_count}`}</Box>
              ))}
            </SimpleGrid>
          )}

          {alertCtx.alert.active && (
            <PisAlert
              status={alertCtx.alert.status}
              title={alertCtx.alert.title}
              desc={alertCtx.alert.description}
              setAlert={alertCtx.setAlert}
            ></PisAlert>
          )}

          <Box bg="gray.200" shadow="md" borderRadius="2xl" p={5}>
            {/* {!props.unit && !props.faq && ( */}
            <Stack
              mb={5}
              spacing={10}
              direction={['column', null, null, 'row']}
            >
              <Search setSearch={props.setSearch} />
              <Button
                colorScheme="teal"
                size="sm"
                variant="outline"
                onClick={exportFile}
                w={['90%', null, null, '30%']}
              >
                Export
              </Button>
            </Stack>
            {/* )} */}
            <TableToolbar
              itemSelected={selectedItems}
              delItemSelected={setSelectedItems}
              // path={location.pathname}
              apiUrl={props.apiUrl}
              delUrl={props.delUrl}
              cekspp={props.cekspp}
              setAlert={alertCtx.setAlert}
              setIsEdit={setIsEdit}
            />

            {/* {isLoading && spinner} */}
            {/* {isError && errorText(isError.message)} */}
            {data && (
              <DataTable
                columns={props.aktif ? columnsAktif : columnsPpdb}
                data={data}
                selectableRows
                onSelectedRowsChange={handleChange}
                pagination
                paginationComponentOptions={paginationComponentOptions}
                highlightOnHover
                noDataComponent="Data tidak ditemukan"
                fixedHeader
                // title="Data Siswa"
              />
            )}
          </Box>
        </>
      )}
    </>
  );
}

export default MuridDataTable;
