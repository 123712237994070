import { FormLabel, Radio, RadioGroup, Stack } from '@chakra-ui/react';
import InputField from '../ui/input/input-field';
import Wrapper from './input-wrapper';

function InputWali(props) {
  return (
    <Wrapper title="Data Wali" changeBg={props.changeBg}>
      <InputField
        id="namaWali"
        name="namaWali"
        label="Nama Lengkap"
        type="text"
        placeholder="nama lengkap wali"
      />
      <InputField
        id="alamatWali"
        name="alamatWali"
        label="Alamat"
        type="text"
        placeholder="alamat Lengkap wali"
      />
      <InputField
        id="tglLahirWali"
        name="tglLahirWali"
        label="Tanggal Lahir (tgl/bln/tahun)"
        type="date"
      />
      <InputField
        id="noWA"
        name="noWA"
        label="Nomor Whatsapp"
        type="number"
        placeholder="08xxxxxxxx"
      />
      {!props.changeBg && (
        <InputField
          id="email"
          name="email"
          label="Email"
          type="email"
          placeholder="exp@example.com"
        />
      )}
      <Stack direction="row" mt={5}>
        <FormLabel htmlFor="jenis-kelamin-wali">Jenis Kelamin</FormLabel>
        <RadioGroup
          id="jenis-kelamin-wali"
          onChange={props.setJenkel}
          value={props.jenkel}
        >
          <Stack direction="row">
            <Radio value="lk">Pria</Radio>
            <Radio value="pr">Wanita</Radio>
          </Stack>
        </RadioGroup>
      </Stack>
    </Wrapper>
  );
}

export default InputWali;
