import Auth from '../../components/auth/auth';

function RegisterPage() {
  return (
    <>
      <Auth isAuthLogin={false} />
    </>
  );
}

export default RegisterPage;
