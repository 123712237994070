import React, { useState } from 'react';
import {
  Badge,
  Box,
  Button,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';

import PisModal from '../../ui/modal/pis-modal';
import SppModalBody from './spp-modal-body';
import PilihButton from './PilihButton';
import Toolbar from './Toolbar';

function Spp({ mutateUrl, ...props }) {
  const [selectedItem, setSelectedItem] = useState([]);
  const [modalBodyText, setModalBodyText] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { siswa, spp, payment } = props.data || {
    siswa: [],
    spp: [],
    payment: [],
  };

  const detailHandler = (payment, anak, s, spp, data = null, appr = false) => {
    if (data) {
      setModalBodyText(
        <SppModalBody
          amount={data.amount}
          nama={data.nama}
          ket={data.keterangan}
          expDate={data.expiredDate}
          noVa={data.custCode}
        />
      );
      onOpen();

      return;
    }

    // const [siswa] = payment.filter(
    //   p => +p.student_id === anak.id && +p.spp_active_id === s.id
    // );

    const vaDetail = payment.filter(p =>
      appr
        ? p.status === 'approved' &&
          +p.student_id === +anak.id &&
          +p.spp_active_id === +s.id
        : p.status !== 'approved' &&
          +p.student_id === +anak.id &&
          +p.spp_active_id === +s.id &&
          new Date(p.exp_va + 'z') > new Date()
    );
    const countVa = payment.filter(p => p.no_va === vaDetail[0].no_va);
    // console.log(countVa);
    const amount = +vaDetail[0].amount * countVa.length;
    const keterangan = spp
      .filter(spp => countVa.find(el => +el.spp_active_id === spp.id))
      .map(date =>
        new Date(date.spp_date).toLocaleDateString('id', {
          month: 'short',
          year: 'numeric',
        })
      )
      .join(', ');

    const bodyText = (
      <SppModalBody
        amount={amount}
        ket={keterangan}
        va={vaDetail[0]}
        appr={appr}
      />
    );

    setModalBodyText(bodyText);
    onOpen();
  };

  const checkPayment = (payment, anak, spp) => {
    const result = payment.find(
      p =>
        +p.student_id === anak.id &&
        +p.spp_active_id === spp.id &&
        p.status.toLowerCase() === 'approved' &&
        p.tgl_bayar !== null
    )
      ? { color: 'green', text: 'LUNAS' }
      : payment.find(
          p =>
            +p.student_id === +anak.id &&
            +p.spp_active_id === +spp.id &&
            new Date(p.exp_va + 'z') > new Date() &&
            p.status.toLowerCase() === 'pending'
        )
      ? { color: 'yellow', text: 'PENDING' }
      : { color: 'red', text: 'BELUM BAYAR' };

    return {
      color: result.color,
      component: <Badge colorScheme={result.color}>{result.text}</Badge>,
    };
  };

  return (
    <>
      {siswa && (
        <>
          {siswa.map((anak, idx) => {
            if (!anak.non_active_date && !anak.is_active) return null;

            return (
              <>
                <Box
                  bg="#fff"
                  shadow="md"
                  borderRadius="2xl"
                  p={5}
                  mb={10}
                  overflow="auto"
                >
                  <Table key={idx} variant="striped" colorScheme="gray" mb={5}>
                    <TableCaption
                      placement="top"
                      fontWeight="semibold"
                      fontSize="lg"
                      mb={5}
                    >
                      {`SPP Ananda ${anak.nama}`}
                    </TableCaption>

                    <Thead>
                      <Tr>
                        <Th>Bulan</Th>
                        <Th>SPP / Bulan</Th>
                        <Th>Status</Th>
                        <Th>Detail</Th>
                        <Th>Aksi</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {spp.map((s, idx) => {
                        if (
                          (anak.non_active_date &&
                            new Date(`${s.spp_date} 07:00:00`).getTime() >=
                              anak.non_active_date * 1000) ||
                          anak.spp_start * 1000 >
                            new Date(`${s.spp_date} 07:00:00`).getTime()
                        )
                          return null;

                        return (
                          <Tr key={idx}>
                            <Td>
                              {new Date(s.spp_date).toLocaleDateString(
                                'id-ID',
                                {
                                  month: 'short',
                                  year: 'numeric',
                                }
                              )}
                            </Td>
                            <Td>{`Rp. ${new Intl.NumberFormat('id-ID').format(
                              anak.unit?.spp
                            )}`}</Td>
                            <Td>{checkPayment(payment, anak, s)?.component}</Td>
                            <Td>
                              {checkPayment(payment, anak, s)?.color ===
                              'red' ? (
                                '-'
                              ) : checkPayment(payment, anak, s)?.color ===
                                'green' ? (
                                <Button
                                  colorScheme="blue"
                                  size="sm"
                                  onClick={() =>
                                    detailHandler(
                                      payment,
                                      anak,
                                      s,
                                      spp,
                                      null,
                                      true
                                    )
                                  }
                                >
                                  Lihat
                                </Button>
                              ) : checkPayment(payment, anak, s)?.color ===
                                'yellow' ? (
                                <Button
                                  colorScheme="blue"
                                  size="sm"
                                  onClick={() =>
                                    detailHandler(payment, anak, s, spp)
                                  }
                                >
                                  Lihat
                                </Button>
                              ) : null}
                            </Td>
                            <Td>
                              {checkPayment(payment, anak, s)?.color !==
                              'red' ? (
                                ''
                              ) : (
                                <PilihButton
                                  spp={s}
                                  siswa={anak}
                                  selectedItem={selectedItem}
                                  setSelectedItem={setSelectedItem}
                                />
                              )}
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                  {selectedItem[0]?.siswa.id === anak.id && (
                    <Toolbar
                      itemSelected={selectedItem}
                      delItems={setSelectedItem}
                      mutateUrl={mutateUrl}
                      detailHandler={detailHandler}
                    />
                  )}
                  {(selectedItem.length === 0 ||
                    selectedItem[0]?.siswa.id !== anak.id) && (
                    <Box
                      bg="gray.300"
                      py={4}
                      px={5}
                      borderRadius="md"
                      border="2px"
                    >
                      <Text
                        fontWeight="medium"
                        mb={3}
                      >{`Pembayaran SPP Ananda ${anak.nama}`}</Text>
                      <Text>Total : Rp. 0</Text>
                      <Text>Pilih bulan yang akan dibayarkan</Text>
                    </Box>
                  )}
                </Box>
              </>
            );
          })}

          {isOpen && (
            <PisModal
              isOpen={isOpen}
              title="Detail Pembayaran SPP"
              body={modalBodyText}
              onClose={onClose}
            />
          )}
        </>
      )}
    </>
  );
}

export default Spp;
