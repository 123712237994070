const now = new Date();

const month = now.getMonth();
const year = now.getFullYear();

export const numMonth = +month;
export const numYear = +year;

export const semester = numMonth <= 7 ? 'genap' : 'ganjil';

export const readableDate = now.toLocaleDateString('id-ID', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

export const readableDateForSpp = date =>
  new Date(date).toLocaleDateString('id-ID', {
    year: 'numeric',
    month: 'short',
  });

export const tahun = [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];

export const bulan = [
  'januari',
  'februari',
  'maret',
  'april',
  'mei',
  'juni',
  'juli',
  'agustus',
  'september',
  'oktober',
  'november',
  'desember',
];
