import { useState, useContext } from 'react';
import { Link as ReactLink, useNavigate, useLocation } from 'react-router-dom';
import { Box, Flex, Heading, Text, Link, Container } from '@chakra-ui/react';
import { Formik } from 'formik';
import { string, ref, object } from 'yup';

import Logo from '../ui/logo/logo';
import { sendData } from '../../lib/fetching';
import AlertContext from '../../store/alert-context';
import PisAlert from '../ui/alert/alert';
import AuthForm from './auth-form';
import AuthContext from '../../store/auth-context';

function Auth({ isAuthLogin }) {
  const [isLoading, setIsLoading] = useState(false);

  const authCtx = useContext(AuthContext);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/user/dashboard';

  const authAlertCtx = useContext(AlertContext);

  const initialValues = {
    email: '',
    password: '',
    passconfirm: '',
  };

  const passwordSchema = isAuthLogin
    ? string().required('wajib diisi')
    : string().required('wajib diisi').min(7, 'minimal 7 karakter');

  const passConfirmSchema = isAuthLogin
    ? null
    : string()
        .required('wajib diisi')
        .oneOf([ref('password'), null], 'password tidak sama');

  const validationSchema = {
    email: string().email('Email tidak valid!').required('email wajib diisi'),
    password: passwordSchema,
    passconfirm: passConfirmSchema,
  };

  return (
    <Container maxW={['sm', null, '2xl', '6xl', '7xl', '8xl']} bg="gray.100">
      <Flex minH="100vh" direction={['column', null, null, 'row']}>
        <Flex
          w="100%"
          bg="gray.100"
          align="center"
          justify="center"
          mb={[5, 5, 0]}
          px={[10, null, null, 0]}
          py={[5, null, 0]}
        >
          <Link as={ReactLink} to="/">
            <Logo height="350px" heightMobile="340px" width="450px" />
          </Link>
        </Flex>

        <Flex w="100%" bg="#fff" direction="column" p={7}>
          <Box my={8} maxW={['100%', null, null, '70%']}>
            <Heading as="h1" size="xl" color="gray.600">
              Selamat Datang
            </Heading>
            <Text color="gray.500" fontSize="lg" my={5}>
              {isAuthLogin
                ? 'Silahkan login dengan menggunakan email & password'
                : 'Silahkan mendaftar dengan menggunakan email saat ppdb'}
            </Text>
            {authAlertCtx.alert.active && (
              <PisAlert
                status={authAlertCtx.alert.status}
                title={authAlertCtx.alert.title}
                desc={authAlertCtx.alert.description}
                setAlert={authAlertCtx.setAlert}
              ></PisAlert>
            )}
          </Box>

          <Formik
            initialValues={initialValues}
            validationSchema={object({ ...validationSchema })}
            onSubmit={async (
              values,
              { setFieldError, setFieldValue, resetForm }
            ) => {
              setIsLoading(true);

              const fetchAPI = isAuthLogin ? 'login' : 'register';

              const dataInput = {
                email: values.email,
                password: values.password,
                password_confirmation: values.passconfirm,
              };

              if (isAuthLogin) {
                try {
                  const res = await sendData(fetchAPI, dataInput);
                  setIsLoading(false);
                  resetForm();
                  authCtx.login(res.data);
                  navigate(from, { replace: true });
                } catch (error) {
                  setIsLoading(false);
                  if (error.status === 401) {
                    setFieldError('email', 'cek email anda');
                    setFieldError('password', 'cek password');
                    setFieldValue('password', '', false);
                  }
                }
              } else {
                try {
                  await sendData(fetchAPI, dataInput);

                  setIsLoading(false);
                  resetForm();
                  navigate('/auth/login', { replace: true });
                  authAlertCtx.setAlert({
                    active: true,
                    status: 'success',
                    description: 'Berhasil. Silahkan login',
                  });
                } catch (error) {
                  setIsLoading(false);

                  if (error.status === 401) {
                    setFieldError('email', error.message);
                  }
                  setFieldValue('password', '', false);
                  setFieldValue('passConfirm', '', false);
                }
              }
            }}
          >
            <AuthForm isAuthLogin={isAuthLogin} isLoading={isLoading} />
          </Formik>
        </Flex>
      </Flex>
    </Container>
  );
}

export default Auth;
