import { capitalizeFirst } from '../../../../../lib/string-util';

const dateSort = (rowA, rowB) => {
  const a = new Date(rowA.expiredDate);
  const b = new Date(rowB.expiredDate);

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const columns = [
  {
    name: 'nama',
    selector: row => capitalizeFirst(row?.nama),
    sortable: true,
  },
  {
    name: 'no VA',
    selector: row => row?.custCode,
    allowOverflow: true,
  },
  {
    name: 'jumlah',
    selector: row => 'Rp.' + new Intl.NumberFormat('id-ID').format(row?.amount),
    sortable: true,
    allowOverflow: true,
  },
  {
    name: 'status bayar',
    selector: row => row?.statusBayar,
    sortable: true,
  },
  {
    name: 'exp date',
    selector: row => {
      let date = new Date(row?.expiredDate);
      date.setHours(date.getHours() + 7);
      return date.toLocaleString('id-ID', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      });
    },
    sortable: true,
    sortFunction: dateSort,
    allowOverflow: true,
  },
  {
    name: 'keterangan',
    selector: row => row?.keterangan,
    wrap: true,
  },
];
