import { useFetchWithSwr } from '../../../lib/fetching-util';
import Faq from '../../../components/admin/faq/Faq';

function FaqPage() {
  const { data, isLoading, isError } = useFetchWithSwr(`faqs`);

  // arrFaq = data.data
  return <Faq data={data?.data} loading={isLoading} error={isError} />;
}

export default FaqPage;
