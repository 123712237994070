import { useContext, useCallback } from 'react';
import { useLocation, Link as ReactLink } from 'react-router-dom';
import { useRowSelect, useTable } from 'react-table';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Box,
  Button,
  Center,
  Text,
  Tfoot,
  TableContainer,
  Link,
  Stack,
  SimpleGrid,
} from '@chakra-ui/react';
import { utils, writeFileXLSX } from 'xlsx';
import { MdAddCircle } from 'react-icons/md';

import AlertContext from '../../../store/alert-context';
import IndeterminateCheckbox from './indeterminateCheckbox';
import TableToolbar from './table-toolbar';
import PisAlert from '../alert/alert';
import Search from '../../admin/search-input';
import { errorText, spinner } from '../../../lib/fetching-util';

function DataTable({ setIsEdit, setIsAdd, data, columns, caption, ...props }) {
  const alertCtx = useContext(AlertContext);

  const location = useLocation();

  const onAddHandler = () => setIsAdd(['true']);

  const selectHooks = [
    {
      id: 'selection',
      Header: 'Pilih',
      Cell: ({ row }) => (
        <div>
          <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        </div>
      ),
    },
  ];

  const tableHooks = hooks => {
    if (!props.addHook) {
      return hooks.visibleColumns.push(columns => [...selectHooks, ...columns]);
    }

    if (props.addHook && (props.laporan || props.detail || props.cekspp)) {
      return hooks.visibleColumns.push(columns => [
        ...columns,
        ...props.addHook,
      ]);
    }

    return hooks.visibleColumns.push(columns => [
      ...selectHooks,
      ...columns,
      ...props.addHook,
    ]);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    // state: { selectedRowIds },
  } = useTable({ columns, data }, useRowSelect, tableHooks);

  const itemSelected = selectedFlatRows.map(row => row.original);

  const arrangeData = data.map(item => {
    const data = {
      nama_siswa: item.nama,
      no_reg: item.no_register,
      alamat_siswa: item.alamat,
      jenkel_siswa: item.jenkel,
      tgl_daftar: new Date(item.created_at).toLocaleDateString('id'),
      tmp_lahir_siswa: item.tmp_lahir,
      tgl_lahir_siswa: item.tgl_lahir,
      unit: item.unit?.nama,
      kelas: item.kelas,
      nama_wali: item.user?.nama,
      email_wali: item.user?.email,
      alamat_wali: item.user?.alamat,
      jenkel_wali: item.user?.jenkel,
      tgl_lahir_wali: item.user?.tgl_lahir,
      no_hp_wa: item.user?.whatsapp,
    };

    // if (props.delUrl === 'student') {
    //   data.unit = item.unit?.nama;
    //   data.kelas = item.kelas;
    // }
    return data;
  });

  const exportFile = useCallback(() => {
    const ws = utils.json_to_sheet(arrangeData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'data');
    writeFileXLSX(wb, 'data.xlsx');
  }, [arrangeData]);

  return (
    <>
      {(props.transaksi || props.kategori) && !props.detail && (
        <Link as={ReactLink} to={`${location.pathname}/tambah`}>
          <Button
            mt={10}
            mb={3}
            leftIcon={<MdAddCircle />}
            colorScheme="teal"
            ml={3}
          >
            Tambah
          </Button>
        </Link>
      )}

      {props.transaksi && !props.detail && (
        <Link as={ReactLink} to={`${location.pathname}/spp`}>
          <Button
            mt={10}
            mb={3}
            leftIcon={<MdAddCircle />}
            colorScheme="teal"
            ml={3}
          >
            Bayar SPP
          </Button>
        </Link>
      )}

      {props.jmlPerUnit && (
        <SimpleGrid
          mt={-20}
          bg="gray.100"
          columns={{ sm: 2, md: 4 }}
          spacing="8"
          pt="3"
          textAlign="center"
          rounded="lg"
        >
          {props.jmlPerUnit.map((unit, idx) => (
            <Box
              key={idx}
              boxShadow="md"
              rounded="md"
              bg="white"
            >{`${unit.nama} : ${unit.students_count}`}</Box>
          ))}
        </SimpleGrid>
      )}

      {alertCtx.alert.active && (
        <PisAlert
          status={alertCtx.alert.status}
          title={alertCtx.alert.title}
          desc={alertCtx.alert.description}
          setAlert={alertCtx.setAlert}
        ></PisAlert>
      )}

      {/* {data.length !== 0 && ( */}
      <Box
        bg="gray.200"
        w={[null, null, null, null, '110%']}
        mt={3}
        shadow="md"
        borderRadius="2xl"
        p={5}
        minH="100vh"
      >
        {!props.unit && !props.faq && (
          <Stack spacing={10} direction={['column', null, null, 'row']}>
            <Search setSearch={props.setSearch} />
            <Button
              colorScheme="teal"
              size="sm"
              variant="outline"
              onClick={exportFile}
              w={['90%', null, null, '30%']}
            >
              Export
            </Button>
          </Stack>
        )}
        <TableToolbar
          itemSelected={itemSelected}
          path={location.pathname}
          apiUrl={props.apiUrl}
          delUrl={props.delUrl}
          cekspp={props.cekspp}
          setAlert={alertCtx.setAlert}
          setIsEdit={setIsEdit}
        />

        {(props.unit || props.faq) && (
          <Button my={3} colorScheme="teal" onClick={onAddHandler}>
            Tambah
          </Button>
        )}

        <TableContainer>
          <Table
            variant="striped"
            colorScheme="gray"
            size="sm"
            minH="100%"
            {...getTableProps()}
          >
            {!itemSelected.length && (
              <TableCaption placement="top">{caption}</TableCaption>
            )}
            <Thead>
              {headerGroups.map(headerGroup => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => {
                    const isNum = column.num
                      ? { isNumeric: true }
                      : { isNumeric: false };

                    return (
                      <Th {...column.getHeaderProps()} {...isNum}>
                        {column.render('Header')}
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            {props.error && errorText(props.error.message)}
            {props.loading && spinner}
            {!props.loading && data && (
              <>
                {data.length === 0 && (
                  <Center mt={5}>
                    <Text bg="gray.500" p={3} color="white" borderRadius="lg">
                      Belum ada data...
                    </Text>
                  </Center>
                )}

                {data.length !== 0 && (
                  <Tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                      prepareRow(row);
                      return (
                        <Tr {...row.getRowProps()}>
                          {row.cells.map(cell => {
                            const isNum = cell.column.num
                              ? { isNumeric: true }
                              : { isNumeric: false };
                            return (
                              <Td {...cell.getCellProps()} {...isNum}>
                                {cell.render('Cell')}
                              </Td>
                            );
                          })}
                        </Tr>
                      );
                    })}
                  </Tbody>
                )}
              </>
            )}

            {props.laporan && (
              <Tfoot>
                <Tr>
                  <Th>Total</Th>
                  <Th></Th>
                  <Th isNumeric>{`Rp. ${new Intl.NumberFormat('id-ID').format(
                    props.totalIn
                  )}`}</Th>
                  <Th isNumeric>{`Rp. ${new Intl.NumberFormat('id-ID').format(
                    props.totalOut
                  )}`}</Th>
                </Tr>
              </Tfoot>
            )}
          </Table>
        </TableContainer>
      </Box>
      {/* )} */}
    </>
  );
}

export default DataTable;
