import {
  FormLabel,
  Input,
  FormHelperText,
  FormControl,
} from '@chakra-ui/react';
import { useField } from 'formik';

function InputField({ label, ...props }) {
  const [field, meta] = useField(props);

  let isInvalid = meta.touched && meta.error;

  if (label === 'Konfirmasi Password') {
    isInvalid =
      (meta.touched && meta.error) ||
      (meta.touched && typeof meta.error === 'string');
  }

  const mbInput = !isInvalid ? { mb: 3 } : null;

  const inputWidth = props.auth
    ? { maxW: ['100%', '100%', '70%'] }
    : { maxW: ['100%', '100%', '90%'] };

  return (
    <FormControl>
      <FormLabel
        fontSize="md"
        fontWeight="light"
        htmlFor={props.id || props.name}
      >
        {label}
      </FormLabel>
      <Input
        {...field}
        {...props}
        size="lg"
        focusBorderColor="teal.500"
        {...inputWidth}
        isInvalid={isInvalid}
        {...mbInput}
      />
      {isInvalid && (
        <FormHelperText mb={3} color="red.300">
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default InputField;
