import {
  useFetchWithSwr,
  spinner,
  errorText,
} from '../../../lib/fetching-util';
import Unit from '../../../components/admin/unit/unit';

function UnitPage() {
  const url = 'unit';
  const { data, isLoading, isError } = useFetchWithSwr(url);

  // console.log(data);

  return (
    <>
      {isError && errorText(isError)}
      {isLoading && spinner}
      {data && <Unit data={data.data} />}
    </>
  );
}

export default UnitPage;
