import { FormControl, HStack, Select } from '@chakra-ui/react';

import { tahun, bulan } from '../../../lib/date-util';

function FormCari({ setTransDate, ...props }) {
  const statusVal = ['sudah bayar', 'belum bayar'];

  return (
    <HStack mb={5}>
      <FormControl w="25%">
        <Select
          id="year"
          value={props.year}
          borderColor="teal"
          onChange={e =>
            setTransDate(prev => ({
              ...prev,
              year: e.target.value,
            }))
          }
        >
          {tahun.map((tahun, idx) => (
            <option key={idx} value={tahun}>
              {tahun}
            </option>
          ))}
        </Select>
      </FormControl>

      {!props.user && (
        <FormControl w="25%">
          <Select
            id="bulan"
            value={props.month}
            borderColor="teal"
            onChange={e =>
              setTransDate(prev => ({
                ...prev,
                month: e.target.value,
              }))
            }
          >
            {bulan.map((bulan, idx) => (
              <option key={idx} value={idx}>
                {bulan}
              </option>
            ))}
          </Select>
        </FormControl>
      )}

      {props.status && (
        <FormControl w="20%">
          <Select
            id="status"
            value={props.status}
            borderColor="teal"
            onChange={e =>
              setTransDate(prev => ({
                ...prev,
                status: e.target.value,
              }))
            }
          >
            {statusVal.map((status, idx) => (
              <option key={idx} value={status}>
                {status}
              </option>
            ))}
          </Select>
        </FormControl>
      )}
    </HStack>
  );
}

export default FormCari;
