import { useEffect } from 'react';
import {
  Divider,
  Heading,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
} from '@chakra-ui/react';
import { MdCheckCircle } from 'react-icons/md';

import FormPendaftaran from './form-pendaftaran';

function Pendaftaran(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Heading
        as="h1"
        display={['none', null, 'block']}
        textAlign="center"
        size="3xl"
        my={3}
        color="teal.500"
      >
        {`Pendaftaran ${props.slug}`}{' '}
      </Heading>
      <Divider />
      <Stack my={5} spacing={3}>
        <Text fontSize="xl">
          {`Selamat datang di halaman pendaftaran `}
          <Text as="span" fontWeight="semibold">
            {props.slug}
          </Text>
        </Text>
      </Stack>
      <hr />
      <Text my={8}>Form pendaftaran ini terdiri dari 2 bagian :</Text>
      <List spacing={3}>
        <ListItem>
          <ListIcon as={MdCheckCircle} color="green.500" />
          Form Data Wali Murid
        </ListItem>
        <ListItem>
          <ListIcon as={MdCheckCircle} color="green.500" />
          Form Data Murid
        </ListItem>
      </List>
      <Text my={8}>
        Silahkan isi semua inputan berikut dengan data sesungguhnya.
      </Text>
      <FormPendaftaran jenjang={props.slug} id={props.id} />
    </>
  );
}

export default Pendaftaran;
