import InputField from '../../../ui/input/input-field';
import Wrapper from '../../../pendaftaran/input-wrapper';
import SelectInput from '../../../ui/input/select';
import {
  useFetchWithSwr,
  spinner,
  errorText,
} from '../../../../lib/fetching-util';

function FormSpp() {
  const { data, isLoading, isError } = useFetchWithSwr('bayar-spp');
  // console.log(data);
  return (
    <>
      {isLoading && spinner}
      {isError && errorText(isError)}
      {data && (
        <Wrapper title="Pembayaran SPP" changeBg>
          {/* <InputField id="date" name="date" label="Tanggal" type="date" /> */}
          <SelectInput
            id="namaMurid"
            name="namaMurid"
            label="Nama Siswa"
            opt={data.data.siswa}
            spp="true"
          />
          <SelectInput
            id="spp"
            name="spp"
            label="SPP"
            sppaktif="true"
            opt={data.data.sppAktif}
          />
          <InputField id="jumlah" name="jumlah" label="Jumlah" type="text" />
        </Wrapper>
      )}
    </>
  );
}

export default FormSpp;
