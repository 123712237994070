import { capitalizeFirst } from '../../../lib/string-util';

export const columnsWali = [
  {
    name: 'nama',
    selector: row => capitalizeFirst(row?.nama),
    sortable: true,
  },
  {
    name: 'alamat',
    selector: row => row?.alamat,
  },
  {
    name: 'email',
    selector: row => row?.email,
  },
  {
    name: 'no whatsapp',
    selector: row => '0' + row?.whatsapp,
    center: true,
  },
  {
    name: 'jenkel',
    selector: row => row?.jenkel,
    sortable: true,
  },
  {
    name: 'Tgl Lahir',
    selector: row => row?.tgl_lahir,
    sortable: true,
    format: ({ tgl_lahir: row }) => {
      return new Date(row)?.toLocaleDateString('id');
    },
  },
];
