import { useState } from 'react';
import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Image,
  Input,
  Stack,
} from '@chakra-ui/react';
import { useSWRConfig } from 'swr';
import { FaSave } from 'react-icons/fa';
import { MdCancel } from 'react-icons/md';

import { sendData } from '../../../lib/fetching';

function AddCarousel({ showForm, initTitle, carouselId, img, url, ...props }) {
  const [title, setTitle] = useState(initTitle || '');
  const [file, setFile] = useState();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const { mutate } = useSWRConfig();

  const imgUrl = url + '/data_img/' + img;

  const uploadHandler = event => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    setFile(formData);
  };

  const submitHandler = () => {
    if (!title) return;
    if (!props.isEdit && !file) return;

    setIsButtonLoading(true);

    let data;

    if (file?.has('file')) {
      file.append('title', title);
      if (props.isEdit) file.append('_method', 'PUT');
    } else {
      data = {
        title,
        _method: 'PUT',
      };
    }

    const fixData = file?.has('file') ? file : data;
    const url = !props.isEdit ? 'carousel' : `carousel/${carouselId}`;

    sendData(url, fixData, 'POST')
      .then(res => {
        // console.log(res);
        setIsButtonLoading(false);
        mutate('carousels');
        props.isEdit ? props.isEdit(false) : showForm(false);
      })
      .catch(e => {
        setIsButtonLoading(false);
      });
  };

  return (
    <Stack w={['100%', null, null, '80%']} spacing={5}>
      <FormControl my={3}>
        <FormLabel>Judul</FormLabel>
        <Input
          borderColor="blue.500"
          value={title}
          onChange={e => setTitle(e.target.value)}
          type="text"
          required
        />
      </FormControl>

      <FormControl>
        <FormLabel>Gambar</FormLabel>
        {props.isEdit && (
          <Image my={3} boxSize="100px" src={imgUrl} alt={title} />
        )}
        <Input type="file" onChange={uploadHandler} />
      </FormControl>
      <ButtonGroup variant="outline" spacing="6">
        <Button
          colorScheme="blue"
          isLoading={isButtonLoading}
          onClick={submitHandler}
          rightIcon={<FaSave />}
        >
          {!props.isEdit ? 'Tambah' : 'Edit'}
        </Button>
        {props.isEdit && (
          <Button
            colorScheme="red"
            onClick={() => props.isEdit(false)}
            rightIcon={<MdCancel />}
          >
            Batal
          </Button>
        )}
      </ButtonGroup>
    </Stack>
  );
}

export default AddCarousel;
