import React, { useState, useEffect } from 'react';
import { useSWRConfig } from 'swr';
import { useDisclosure } from '@chakra-ui/react';
import { Button } from '@chakra-ui/react';
import { FaWhatsapp } from 'react-icons/fa';

import DataTable from '../../ui/table/data-table';
import { kolomMurid } from '../../../lib/columns-table';

import EditPage from '../../../pages/admin/murid/edit/edit';
import { sendData } from '../../../lib/fetching';
import PisModal from '../../ui/modal/pis-modal';

function Murid(props) {
  const [siswa, setSiswa] = useState();
  const [dataAktifasi, setDataAktifasi] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isEdit, setIsEdit] = useState([]);
  const { mutate } = useSWRConfig();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isEdit]);

  let kolomM = kolomMurid;
  let muridHooks;

  const openNewTab = url => window.open(url, '_blank').focus();

  const onSendMsgHandler = async (wali, nama, uangDaftar, rek) => {
    const panggilan = wali.jenkel === 'lk' ? 'Bapak' : 'Ibu';

    const msg = `Assalamu'alaikum ${panggilan} ${wali.nama}.
    Alhamdulillah data pendaftaran ananda *${nama}* telah kami terima. Untuk tahap selanjutnya silahkan ${panggilan} transfer uang pendaftaran *Rp.${uangDaftar},-* ke rekening
    *Bank Nagari : ${rek}*
    *a/n : YAYASAN PERTIWI KOTA PADANG.*
    Dan *wajib* memberikan catatan / keterangan nama anak ketika akan transfer. Bukti transfer silahkan ${panggilan} upload ke nomor ini.
    Terima kasih.`;

    const url = `https://api.whatsapp.com/send?phone=62${wali.whatsapp}&text=${msg}`;

    openNewTab(url);
  };

  const onActivateHandler = async () => {
    const wali = dataAktifasi?.user;

    const panggilan = wali.jenkel === 'lk' ? 'Bapak' : 'Ibu';

    sendData(
      `student/ppdb/active?wali=${wali.id}&student=${dataAktifasi?.id}`,
      null,
      'PUT'
    )
      .then(_ => {
        mutate(props.ppdb);
      })
      .finally(() => onClose());

    const msg = `Alhamdulillah uang pendaftaran untuk ananda *${dataAktifasi.nama}* telah kami terima. Selanjutnya jika ${panggilan} belum punya akun, silahkan mendaftarkan akun di link berikut: https://ppdb.pertiwi.sch.id/auth/signup`;

    const url = `https://api.whatsapp.com/send?phone=62${wali.whatsapp}&text=${msg}`;

    openNewTab(url);
  };

  const changeStudentStatusHandler = (nama, id) => {
    setSiswa({ id, nama });
    onOpen();
  };

  if (props.aktif) {
    muridHooks = [
      {
        id: 'status',
        Header: 'Status',
        Cell: ({ row }) => (row.original.is_active ? 'Aktif' : 'Tidak Aktif'),
      },
      {
        id: 'aksi',
        Header: 'Aksi',
        Cell: ({ row }) => (
          <Button
            colorScheme="teal"
            onClick={() =>
              changeStudentStatusHandler(row.original.nama, row.original.id)
            }
          >
            non-aktifkan
          </Button>
        ),
      },
    ];
  }

  if (props.ppdb) {
    muridHooks = [
      {
        id: 'kirimPesan',
        Header: 'Kirim WA',
        Cell: ({ row }) => (
          <Button
            colorScheme="whatsapp"
            size="sm"
            variant="outline"
            rightIcon={<FaWhatsapp />}
            onClick={() => {
              onSendMsgHandler(
                row.original.user,
                row.original.nama,
                row.original.unit?.uang_pendaftaran,
                row.original.unit?.rek
              );
            }}
          >
            Kirim Pesan
          </Button>
        ),
      },
      {
        id: 'aksi',
        Header: 'Aksi',
        Cell: ({ row }) => (
          <Button
            colorScheme="teal"
            size="sm"
            onClick={() => {
              setDataAktifasi(row.original);
              onOpen();
            }}
          >
            Aktifkan
          </Button>
        ),
      },
    ];
  }

  const dataMurid = React.useMemo(() => props.data || [], [props.data]);
  const columns = React.useMemo(() => kolomM, [kolomM]);

  let date;

  if (props.cekspp) {
    const [year, month] = [props.dataAttr.year, props.dataAttr.month];

    date = new Date(year, month).toLocaleDateString('id-ID', {
      year: 'numeric',
      month: 'long',
    });
  }

  const caption = props.cekspp ? `SPP - ${date}` : 'Data Siswa';

  // console.log(props.jmlPerUnit);

  return (
    <>
      {isEdit.length !== 0 && (
        <EditPage
          data={isEdit}
          redirect={setIsEdit}
          ppdb={props.ppdb || false}
        />
      )}
      {isEdit.length === 0 && (
        <>
          <DataTable
            setIsEdit={setIsEdit}
            setSearch={props.setSearch}
            jmlPerUnit={props.jmlPerUnit}
            loading={props.loading}
            error={props.error}
            addHook={muridHooks}
            apiUrl={props.ppdb}
            delUrl="student"
            data={dataMurid}
            cekspp={props.cekspp}
            columns={columns}
            caption={caption}
          />
        </>
      )}
      <PisModal
        title={siswa?.nama ? `Non aktifkan ${siswa?.nama}` : 'Aktifkan ?'}
        isOpen={isOpen}
        id={siswa?.id}
        aktifasiHandler={dataAktifasi?.user ? onActivateHandler : null}
        namaSiswaAkfifasi={dataAktifasi?.nama}
        onClose={onClose}
      />
    </>
  );
}

export default Murid;
