import React, { useState, useEffect } from 'react';

import DataTable from '../../ui/table/data-table';
import { kolomUnit } from '../../../lib/columns-table';
import EditPage from '../../../pages/admin/unit/edit/edit';
import AddPage from '../../../pages/admin/unit/tambah/add';

function Unit(props) {
  const [isEdit, setIsEdit] = useState([]);
  const [isAdd, setIsAdd] = useState([]);

  const dataUnit = React.useMemo(() => props.data, [props.data]);
  const columns = React.useMemo(() => kolomUnit, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isEdit, isAdd]);

  return (
    <>
      {isEdit.length !== 0 && <EditPage data={isEdit} redirect={setIsEdit} />}
      {isAdd.length !== 0 && <AddPage redirect={setIsAdd} />}
      {isEdit.length === 0 && isAdd.length === 0 && (
        <DataTable
          setIsEdit={setIsEdit}
          setIsAdd={setIsAdd}
          delUrl="unit"
          data={dataUnit}
          columns={columns}
          caption="Data Unit"
          unit
        />
      )}
    </>
  );
}

export default Unit;
