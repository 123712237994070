import { useState } from 'react';

// import Wali from '../../../components/admin/wali/wali';
import WaliDataTable from '../../../components/admin/wali/WaliDataTable';
import { useFetchWithSwr } from '../../../lib/fetching-util';

function DaftarWali() {
  const [search, setSearch] = useState('');
  const { data, isLoading, isError } = useFetchWithSwr(`wali?search=${search}`);

  // arrayWali = data.data
  return (
    <WaliDataTable
      data={data?.data}
      setSearch={setSearch}
      loading={isLoading}
      error={isError}
    />
    // <Wali
    //   data={data?.data}
    //   setSearch={setSearch}
    //   loading={isLoading}
    //   error={isError}
    // />
  );
}

export default DaftarWali;
