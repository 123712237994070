import FormFormik from '../../../../components/ui/form/form-formik';
import { waliSchema } from '../../../../lib/formik-util';
import InputWali from '../../../../components/pendaftaran/input-wali';

function EditPage({ data, redirect }) {
  const initialValues = {
    namaWali: data[0]?.nama,
    alamatWali: data[0]?.alamat,
    tglLahirWali: data[0]?.tgl_lahir,
    noWA: `0${data[0]?.whatsapp}`,
    // noWA: `${data[0]?.whatsapp}`,
    id: data[0]?.id,
  };

  return (
    <>
      {data.length === 0 && <div>Tidak ada data</div>}
      {data.length !== 0 && (
        <FormFormik
          edit={initialValues}
          schema={waliSchema}
          ComponentForm={InputWali}
          jenkel={data[0]?.jenkel}
          changeBg={true}
          redirect={redirect}
        />
      )}
    </>
  );
}

export default EditPage;
