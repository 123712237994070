import { useState } from 'react';
import { useSWRConfig } from 'swr';
import { Button, useDisclosure } from '@chakra-ui/react';

import { sendData } from '../../../lib/fetching';
import PisModal from '../../ui/modal/pis-modal';
import { openNewTab } from './sendMsg';

function Aktifasi({ row }) {
  const [dataAktifasi, setDataAktifasi] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate } = useSWRConfig();

  const onActivateHandler = () => {
    const wali = dataAktifasi?.user;
    const panggilan = wali.jenkel === 'lk' ? 'Bapak' : 'Ibu';
    sendData(
      `student/ppdb/active?wali=${wali.id}&student=${dataAktifasi?.id}`,
      null,
      'PUT'
    )
      .then(_ => {
        mutate('student/ppdb?search=');
      })
      .finally(() => onClose());
    const msg = `Alhamdulillah uang pendaftaran untuk ananda *${dataAktifasi.nama}* telah kami terima. Selanjutnya jika ${panggilan} belum punya akun, silahkan mendaftarkan akun di link berikut: https://ppdb-app.dareliman.or.id/auth/signup`;
    const url = `https://api.whatsapp.com/send?phone=62${wali.whatsapp}&text=${msg}`;
    openNewTab(url);
  };

  return (
    <>
      <Button
        size="xs"
        variant="outline"
        colorScheme="blue"
        onClick={() => {
          setDataAktifasi(row);
          onOpen();
        }}
      >
        aktifkan
      </Button>
      <PisModal
        title="Aktifkan ?"
        isOpen={isOpen}
        aktifasiHandler={dataAktifasi?.user ? onActivateHandler : null}
        namaSiswaAkfifasi={dataAktifasi?.nama}
        onClose={onClose}
      />
    </>
  );
}

export default Aktifasi;
