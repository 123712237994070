import { useState } from 'react';

// import Murid from '../../../components/admin/murid/murid';
import MuridDataTable from '../../../components/admin/murid/MuridDataTable';
import {
  useFetchWithSwr,
  errorText,
  spinner,
} from '../../../lib/fetching-util';

function PpdbPage() {
  const [search, setSearch] = useState('');

  const mutateUrl = 'student/ppdb?search=';
  const { data, isLoading, isError } = useFetchWithSwr(`${mutateUrl}${search}`);

  return (
    <MuridDataTable
      data={data?.data?.siswa}
      setSearch={setSearch}
      ppdb={mutateUrl}
      jmlPerUnit={data?.data?.unit}
      isLoading={isLoading}
      isError={isError}
      errorText={errorText}
      spinner={spinner}
      delUrl="student"
      apiUrl={mutateUrl}
    />

    // <Murid
    //   data={data?.data?.siswa}
    //   jmlPerUnit={data?.data?.unit}
    //   ppdb={mutateUrl}
    //   setSearch={setSearch}
    //   loading={isLoading}
    //   error={isError}
    // />
  );
}

export default PpdbPage;
