import { useContext } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Flex,
  Box,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { FiLogOut } from 'react-icons/fi';

import { capitalizeFirst } from '../../../lib/string-util';
import Footer from '../../home/footer/footer';
import Header from '../../home/header/header';

import Sidebar from './sidebar';
import AuthContext from '../../../store/auth-context';

function Layout() {
  const authCtx = useContext(AuthContext);
  const name = authCtx.nama;

  const { pathname } = useLocation();
  const [, ...path] = pathname?.split('/');

  const logoutHandler = () => {
    authCtx.logout();
  };

  const classes = {
    container: {
      maxW: 'container.lg',
      // mt: [null, null, null, 5],
    },
    bgGrad: 'linear(to-t, gray.50, gray.100, gray.50)',
  };

  return (
    <Flex minH="100vh" maxW="1400px" mx="auto" bgGradient={classes.bgGrad}>
      <Sidebar />
      <Container {...classes.container}>
        <Flex direction="column" minH="100vh" overflow="auto">
          {/* <Breadcrumb
            fontWeight="medium"
            fontSize="sm"
            display={['none', null, null, 'block']}
          >
            {path.map((path, idx) => {
              if (idx <= 2) {
                return (
                  <BreadcrumbItem key={idx}>
                    <Text fontSize="lg">{capitalizeFirst(path)}</Text>
                  </BreadcrumbItem>
                );
              }
              return null;
            })}
          </Breadcrumb> */}

          <Box
            mt={10}
            shadow="md"
            color="whatsapp.700"
            borderRadius="lg"
            p={7}
            borderLeft="4px"
            fontWeight="bold"
            display={['none', null, null, 'block']}
          >
            <Flex justify="space-between">
              <Text>{capitalizeFirst(name)}</Text>
              <Text>{path[path.length - 1]}</Text>
              <Tooltip label="logout" bg="gray.300" color="black">
                <span>
                  <FiLogOut onClick={logoutHandler} />
                </span>
              </Tooltip>
            </Flex>
          </Box>

          <Box display={[null, null, null, 'none']}>
            <Header />
          </Box>

          <Box
            as="main"
            flex={1}
            mt={[null, null, null, 10]}
            mb={10}
            fontSize="sm"
          >
            <Outlet />
          </Box>
        </Flex>
        <Footer />
      </Container>
    </Flex>
  );
}

export default Layout;
