import FormFormik from '../../../../components/ui/form/form-formik';
import { faqSchema } from '../../../../lib/formik-util';
import InputFaq from '../../../../components/admin/faq/InputFaq';

function AddPage({ redirect }) {
  const initialValues = {
    title: '',
    content: '',
  };

  return (
    <FormFormik
      tambah={initialValues}
      schema={faqSchema}
      ComponentForm={InputFaq}
      changeBg={true}
      redirect={redirect}
      faq
    />
  );
}

export default AddPage;
