import {
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import { readableDateForSpp } from '../../../../lib/date-util';

function Spp({ data }) {
  return (
    <TableContainer>
      <Table variant="striped" colorScheme="gray.600">
        <TableCaption placement="top">SPP Aktif</TableCaption>
        <Thead>
          <Tr>
            <Th>no</Th>
            <Th>bulan</Th>
            <Th>status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, idx) => (
            <Tr key={idx}>
              <Td>{idx + 1}</Td>
              <Td>{readableDateForSpp(item.spp_date)}</Td>
              <Td>Aktif</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export default Spp;
