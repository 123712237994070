import { Box, Heading, Stack } from '@chakra-ui/react';

function Wrapper(props) {
  const parentClasses = props.changeBg
    ? {
        bg: 'gray.600',
        w: ['100%', null, null, '50%'],
        p: 10,
        color: 'white',
        borderRadius: 'lg',
      }
    : { w: ['90%', null, null, '40%'] };

  return (
    <Stack {...parentClasses} spacing={5} py={5}>
      <Heading as="h3" size="lg" textAlign={['center', null, null, 'left']}>
        {props.title}
      </Heading>
      <Box>{props.children}</Box>
    </Stack>
  );
}

export default Wrapper;
