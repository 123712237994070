import { useState } from 'react';

import { numYear, numMonth } from '../../../../lib/date-util';
import FormCari from '../../../../components/ui/form/form-cari';
import { useFetchWithSwr } from '../../../../lib/fetching-util';
import Murid from '../../../../components/admin/murid/murid';

function CekSppPage() {
  const [search, setSearch] = useState('');
  const [transDate, setTransDate] = useState({
    year: numYear,
    month: numMonth,
    status: 'sudah bayar',
  });

  const { data, isLoading, isError } = useFetchWithSwr(
    `cek-spp?year=${transDate.year}&month=${transDate.month}&status=${transDate.status}&search=${search}`
  );

  return (
    <>
      <FormCari
        setTransDate={setTransDate}
        year={transDate.year}
        month={transDate.month}
        status={transDate.status}
      />
      <Murid
        data={data?.data}
        cekspp="true"
        dataAttr={transDate}
        setSearch={setSearch}
        loading={isLoading}
        error={isError}
      />
    </>
  );
}

export default CekSppPage;
