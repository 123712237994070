import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Box, Stack } from '@chakra-ui/react';
import { FiCalendar } from 'react-icons/fi';

import { columns } from './kolom';
import Search from '../../../search-input';
import DatePopover from '../../../../ui/popover/DatePopover';

function ReportDataTable({
  data,
  // isError,
  isLoading,
  spinner,
  // errorText,
  setDate,
}) {
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setFilteredData(
      data?.filter(
        item =>
          item.nama?.toLowerCase().includes(searchText) ||
          item.custCode?.includes(searchText) ||
          item.expiredDate?.includes(searchText)
      )
    );
  }, [searchText, data]);

  const paginationComponentOptions = {
    rowsPerPageText: 'Show',
    rangeSeparatorText: 'dari',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'All',
  };

  return (
    <>
      <Box bg="gray.200" shadow="md" borderRadius="2xl" p={5}>
        <Stack mb={5} spacing={10} direction={['column', null, null, 'row']}>
          <Search setSearch={setSearchText} />
          <DatePopover icon={<FiCalendar />} setDate={setDate} />
        </Stack>

        {isLoading && spinner}
        {/* {isError && errorText(isError.message)} */}
        {data && (
          <DataTable
            columns={columns}
            data={filteredData}
            // selectableRows
            // onSelectedRowsChange={handleChange}
            pagination
            paginationComponentOptions={paginationComponentOptions}
            highlightOnHover
            noDataComponent="Data tidak ditemukan"
            fixedHeader
            // title="Data Siswa"
          />
        )}
      </Box>
    </>
  );
}

export default ReportDataTable;
