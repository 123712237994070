import React from 'react';
import TextField from '../../ui/input/text-field';
import InputField from '../../ui/input/input-field';
import Wrapper from '../../pendaftaran/input-wrapper';

function InputFaq(props) {
  return (
    <Wrapper title="Data FAQ" changeBg={props.changeBg}>
      <InputField
        id="title"
        name="title"
        label="Judul"
        type="text"
        placeholder="Judul"
      />
      <TextField
        id="content"
        name="content"
        placeholder="Konten"
        label="Konten"
      />
    </Wrapper>
  );
}

export default InputFaq;
