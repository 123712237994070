import { useState, useEffect } from 'react';
import {
  Heading,
  Flex,
  Stack,
  Button,
  Box,
  useDisclosure,
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import InputField from '../../ui/input/input-field';
import PisModal from '../../ui/modal/pis-modal';
import { sendData } from '../../../lib/fetching';

function ChangePassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBodyText, setModalBodyText] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const initialValues = {
    oldPass: '',
    newPass: '',
  };

  const validationSchema = {
    oldPass: Yup.string().required('wajib diisi'),
    newPass: Yup.string().required('wajib diisi').min(7, 'minimal 7 karakter'),
  };

  return (
    <>
      <Flex
        justify="center"
        maxW={['100%', null, null, '80%']}
        bg="gray.600"
        color="white"
        p={10}
        borderRadius="lg"
        shadow="lg"
      >
        <Box>
          <Heading as="h2">Ganti Password</Heading>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object(validationSchema)}
            onSubmit={(values, { setFieldValue, setFieldError, resetForm }) => {
              setIsLoading(true);

              const data = {
                newPass: values.newPass,
                oldPass: values.oldPass,
              };

              sendData('usrchp', data, 'PUT')
                .then(res => {
                  setIsLoading(false);
                  setModalTitle('Berhasil!');
                  setModalBodyText(res.message);
                  onOpen();
                  resetForm();
                })
                .catch(_ => {
                  setIsLoading(false);
                  setFieldError('oldPass', 'cek password lama anda');
                  setFieldValue('newPass', '', false);
                });
            }}
          >
            <Form>
              <Stack spacing={5} mt={5}>
                <InputField
                  id="oldPass"
                  name="oldPass"
                  label="Password Lama"
                  type="password"
                />
                <InputField
                  id="newPass"
                  name="newPass"
                  label="Password Baru"
                  type="password"
                />
                <Button
                  type="submit"
                  rightIcon={<ArrowForwardIcon />}
                  colorScheme="teal"
                  variant="solid"
                  fontSize="sm"
                  w={['100%', '100%', '90%']}
                  isLoading={isLoading}
                  loadingText="Proses..."
                >
                  Ganti Password
                </Button>
              </Stack>
            </Form>
          </Formik>
        </Box>
      </Flex>
      {isOpen && (
        <PisModal
          isOpen={isOpen}
          title={modalTitle}
          body={modalBodyText}
          onClose={onClose}
          redirect="/user/dashboard"
        />
      )}
    </>
  );
}

export default ChangePassword;
