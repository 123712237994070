import { capitalizeFirst } from './string-util';

export const kolomWali = [
  {
    Header: 'Nama',
    accessor: 'nama',
    Cell: ({ cell: { value } }) => capitalizeFirst(value),
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'No.HP / WA',
    accessor: 'whatsapp',
    Cell: ({ cell: { value } }) => `0${value}`,
  },
  {
    Header: 'Alamat',
    accessor: 'alamat',
  },
  {
    Header: 'Tgl Lahir',
    accessor: 'tgl_lahir',
    Cell: ({ cell: { value } }) => new Date(value).toLocaleDateString('id'),
  },
];

export const kolomMurid = [
  {
    Header: 'Nama',
    accessor: 'nama',
    Cell: ({ cell: { value } }) => capitalizeFirst(value),
  },
  {
    Header: 'No Reg',
    accessor: 'no_register',
  },
  {
    Header: 'Unit',
    accessor: 'unit.nama',
    Cell: ({ cell: { value } }) => capitalizeFirst(value),
  },
  {
    Header: 'Kelas',
    accessor: 'kelas',
  },
  {
    Header: 'Tgl Daftar',
    accessor: 'created_at',
    Cell: ({ cell: { value } }) => new Date(value).toLocaleDateString('id'),
  },
  {
    Header: 'Tgl Lahir',
    accessor: 'tgl_lahir',
    Cell: ({ cell: { value } }) => new Date(value).toLocaleDateString('id'),
  },
  {
    Header: 'Alamat',
    accessor: 'alamat',
  },
  {
    Header: 'HP / WA',
    accessor: 'user.whatsapp',
    Cell: ({ cell: { value } }) => `0${value}`,
  },
];

export const kolomKategoriTransaksi = [
  {
    Header: 'Kategori',
    accessor: 'name',
  },
  {
    Header: 'Tipe',
    accessor: 'type',
  },
  {
    Header: 'Kode',
    accessor: 'kode',
  },
];

export const kolomUnit = [
  {
    Header: 'Unit',
    accessor: 'nama',
  },
  {
    Header: 'Deskripsi',
    accessor: 'desc',
  },
  {
    Header: 'Alamat',
    accessor: 'alamat',
  },
  {
    Header: 'Warna',
    accessor: 'grad',
  },
];

export const kolomFaq = [
  {
    Header: 'Judul',
    accessor: 'title',
  },
  {
    Header: 'Konten',
    accessor: 'content',
  },
];

export const kolomTransaksi = [
  {
    Header: 'No.Kwitansi',
    accessor: 'nmrKwitansi',
  },
];

export const kolomLaporan = [
  {
    Header: 'Kategori',
    accessor: 'kategori',
  },
];
