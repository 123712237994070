import { useParams } from 'react-router-dom';

import Header from '../../components/home/header/header';
import Footer from '../../components/home/footer/footer';
import Pendaftaran from '../../components/pendaftaran/pendaftaran';

function HalamanPendaftaran() {
  const params = useParams();

  return (
    <>
      <Header />
      <Pendaftaran slug={params?.unit?.toUpperCase()} id={params?.id} />
      <Footer />
    </>
  );
}

export default HalamanPendaftaran;
