export const arrangeData = data =>
  data.map(item => {
    const data = {
      nama_siswa: item.nama,
      no_reg: item.no_register,
      alamat_siswa: item.alamat,
      jenkel_siswa: item.jenkel,
      tgl_daftar: new Date(item.created_at).toLocaleDateString('id'),
      tmp_lahir_siswa: item.tmp_lahir,
      tgl_lahir_siswa: item.tgl_lahir,
      unit: item.unit?.nama,
      kelas: item.kelas,
      nama_wali: item.user?.nama,
      email_wali: item.user?.email,
      alamat_wali: item.user?.alamat,
      jenkel_wali: item.user?.jenkel,
      tgl_lahir_wali: item.user?.tgl_lahir,
      no_hp_wa: item.user?.whatsapp,
    };

    return data;
  });
