import FormFormik from '../../../../components/ui/form/form-formik';
import { unitSchema } from '../../../../lib/formik-util';
import InputUnit from '../../../../components/pendaftaran/input-unit';

function AddPage({ redirect }) {
  const initialValues = {
    nama: '',
    alamat: '',
    spp: '',
    uang_pendaftaran: '',
    status_ppdb: undefined,
    desc: '',
    grad: '',
  };

  return (
    <FormFormik
      tambah={initialValues}
      schema={unitSchema}
      ComponentForm={InputUnit}
      changeBg={true}
      redirect={redirect}
      unit
    />
  );
}

export default AddPage;
