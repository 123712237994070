import { useState } from 'react';
// import DataTable from 'react-data-table-component';

import {
  useFetchWithSwr,
  spinner,
  errorText,
} from '../../../lib/fetching-util';
import MuridDataTable from '../../../components/admin/murid/MuridDataTable';

function MuridDtPage() {
  const [search, setSearch] = useState('');

  // const mutateUrl = 'student/ppdb?search=';
  const { data, isLoading, isError } = useFetchWithSwr(
    `student?search=${search}`
  );

  return (
    <>
      {isError && errorText(isError.message)}
      {isLoading && spinner}
      {data && <MuridDataTable data={data.data} setSearch={setSearch} />}
    </>
  );
}

export default MuridDtPage;
