import { useContext } from 'react';

import FormFormik from '../../../../../components/ui/form/form-formik';
import FormSpp from '../../../../../components/admin/keuangan/transaksi/form-spp';
import { sppSchema } from '../../../../../lib/formik-util';
import AlertContext from '../../../../../store/alert-context';
import PisAlert from '../../../../../components/ui/alert/alert';

function BayarSppPage() {
  const alertCtx = useContext(AlertContext);

  const initialValues = {
    date: '',
    namaMurid: '',
    tahun: '',
    bulan: '',
    jumlah: '',
  };

  return (
    <>
      {alertCtx.alert.active && (
        <PisAlert
          status={alertCtx.alert.status}
          title={alertCtx.alert.title}
          desc={alertCtx.alert.description}
          setAlert={alertCtx.setAlert}
        ></PisAlert>
      )}

      <FormFormik
        tambah={initialValues}
        schema={sppSchema}
        ComponentForm={FormSpp}
        spp="true"
      />
    </>
  );
}

export default BayarSppPage;
