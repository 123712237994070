import { Box, Text, Flex, Icon } from '@chakra-ui/react';
import { FaBookReader } from 'react-icons/fa';

function DashboardItem(props) {
  return (
    <Box
      as="li"
      p={3}
      display="flex"
      justifyContent="center"
      bg="#fff"
      borderRadius="lg"
      shadow="sm"
    >
      <Flex align="center" justify="space-between">
        <Box>
          <Text color="gray.400" fontWeight="semibold">
            {props.text}
          </Text>
          <Text fontWeight="bold">{`${props.sum} Siswa`}</Text>
        </Box>
        <Box
          ml={5}
          py={2}
          px={3}
          bg="teal.400"
          color="#fff"
          borderRadius="xl"
          fontSize="sm"
        >
          <Icon as={FaBookReader} />
        </Box>
      </Flex>
    </Box>
  );
}

export default DashboardItem;
