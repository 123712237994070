import { useState } from 'react';

import { useFetchWithSwr, spinner } from '../../../../lib/fetching-util';
import ReportDataTable from '../../../../components/admin/keuangan/spp/report/ReportDataTable';

const date = new Date();
const year = date.getFullYear();
const month = date.toLocaleString('id-ID', { month: '2-digit' });
const day = date.toLocaleString('id-ID', { day: '2-digit' });
const dateString = year.toString().concat(month, day);

function ReportVaPage() {
  const [vaDate, setVaDate] = useState({ start: dateString, end: dateString });
  const { data, isLoading, isError } = useFetchWithSwr(
    `reportva?start=${vaDate.start}&end=${vaDate.end}`
  );

  // console.log(data?.data);

  return (
    <ReportDataTable
      data={data?.data}
      spinner={spinner}
      isLoading={isLoading}
      isError={isError}
      setDate={setVaDate}
    />
  );
}

export default ReportVaPage;
