import React, { useState, useEffect } from 'react';

import DataTable from '../../ui/table/data-table';
import { kolomFaq } from '../../../lib/columns-table';
import EditPage from '../../../pages/admin/faq/edit/edit';
import AddPage from '../../../pages/admin/faq/tambah/add';

function Faq(props) {
  const [isEdit, setIsEdit] = useState([]);
  const [isAdd, setIsAdd] = useState([]);

  const dataFaq = React.useMemo(() => props.data, [props.data]);
  const columns = React.useMemo(() => kolomFaq, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isEdit, isAdd]);

  return (
    <>
      {isEdit.length !== 0 && <EditPage data={isEdit} redirect={setIsEdit} />}
      {isAdd.length !== 0 && <AddPage redirect={setIsAdd} />}
      {isEdit.length === 0 && isAdd.length === 0 && !props.loading && (
        <DataTable
          setIsEdit={setIsEdit}
          setIsAdd={setIsAdd}
          delUrl="faqs"
          data={dataFaq}
          columns={columns}
          caption="FAQ"
          faq
        />
      )}
    </>
  );
}

export default Faq;
