import { Link as ReactLink } from 'react-router-dom';
import { Button, Text, VStack, Link } from '@chakra-ui/react';
import { Form } from 'formik';
import InputField from '../ui/input/input-field';

function AuthForm({ isAuthLogin, isLoading }) {
  return (
    <Form autoComplete="off">
      <InputField
        id="email"
        name="email"
        label="Email"
        type="email"
        placeholder="fulan@contoh.com"
        auth="true"
      />
      <InputField
        id="password"
        name="password"
        label="Password"
        type="password"
        placeholder="Kata sandi"
        auth="true"
      />
      {!isAuthLogin && (
        <InputField
          id="passconfirm"
          name="passconfirm"
          label="Konfirmasi Password"
          type="password"
          placeholder="Ulangi kata sandi"
          auth="true"
        />
      )}

      <VStack mt={10} maxW={['100%', '100%', '70%']} spacing={5}>
        <Button
          colorScheme="teal"
          size="lg"
          type="submit"
          isFullWidth
          isLoading={isLoading}
          loadingText="Loading..."
        >
          {isAuthLogin ? 'Masuk' : 'Daftar'}
        </Button>
        <Text>
          {isAuthLogin ? 'Belum punya akun?' : 'Sudah terdaftar?'}{' '}
          <Button type="reset" colorScheme="teal" variant="link">
            <Link
              as={ReactLink}
              to={isAuthLogin ? '/auth/signup' : '/auth/login'}
            >
              {isAuthLogin ? 'Daftar disini' : 'Sign In'}
            </Link>
          </Button>
        </Text>
      </VStack>
    </Form>
  );
}

export default AuthForm;
